/* eslint-disable import/no-anonymous-default-export */
import { base_url, getCredentiTokenHeaders } from "./ApiUtils";

export default {
  get(path: string, params?: object): Promise<any> {
    var requestOptions: RequestInit = {
      method: "GET",
    };

    var url = new URL(`${path}`);

    return fetch(url.href, requestOptions)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log(error);
      });
  },

  post(path: string, data: object): Promise<any> {
    var requestOptions = {
      method: "POST",
      headers: getCredentiTokenHeaders(),
      body: JSON.stringify(data),
    };
    var url = new URL(`${path}`);
    return fetch(url.href, requestOptions).then((res) => {
      return res.json();
    });
  },

  put(path: string, data: object): Promise<any> {
    var requestOptions = {
      method: "PUT",
      headers: getCredentiTokenHeaders(),
      body: JSON.stringify(data),
    };

    return fetch(`${base_url}/${path}`).then((res) => res.json());
  },

  delete(path: string, data: object): Promise<any> {
    var requestOptions = {
      method: "DELETE",
      headers: getCredentiTokenHeaders(),
      body: JSON.stringify(data),
    };

    return fetch(`${base_url}/${path}`).then((res) => res.json());
  },
};
