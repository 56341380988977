import { Alert, Button, Divider, Skeleton } from "antd";
import Password from "antd/lib/input/Password";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiService from "../../Api.service";
import Urls, {
  apiChangePasswordErrorMsg,
  apiResetPasswordErrorMsg,
  APITimeoutErrorMsg,
  base_url,
  changePasswordLabel,
  changePasswordSuccessMsg,
  confirmationRoute,
  forgotPasswordLabel,
  forgotPasswordSuccessMsg,
  passwordEmptyMsg,
  recoveryHomePageRoute
} from "../../ApiUtils";
import UserContext from "../../features/userContext";
import {
  clearSessionStorage,
  componentMountCheck,
  getAlertSummary,
  getSessionToken, verifyErrorAndShowMessage
} from "../../helpers/helper.service";
import {
  Branding
} from "../../interfaces/api.interface";
import ssprService from "../../services/sspr.service";
import { openNotification } from "../Common/Notification";
import RichTextViewer from "../Common/RichTextViewer";
import "./RecoveryOption.css";

export default function RecoveryOption() {
  const [oldPassword, setOldPassword] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [resetPassword, setResetPassword] = useState("");
  const [operation, setSelectedOperation] = useState("");
  const navigate = useNavigate();
  const [userState, setUserState] = useContext(UserContext);
  const [brandingDetails, setBrandingDetails] = useState<Branding>()
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  useEffect(() => {

    componentMountCheck(location.state as string);
    setSelectedOperation(userState.selectedOperation);
    setBrandingDetails(ssprService.getBrandingDetails());
    if (!!ssprService.getBrandingDetails() && !!ssprService.getBrandingDetails().background_image)
      document.body.style.backgroundImage = `url(${ssprService.getBrandingDetails().background_image}?v=3)`;
    setLoading(false);
  }, []);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);


  const validatePassword = () => {
    if (!newPassword) {
      setButtonLoading(false);
      openNotification("info", passwordEmptyMsg);
      return false;
    } else if (newPassword !== resetPassword) {
      setButtonLoading(false);
      openNotification(
        "error",
        "New Password and Repeat Password does not match"
      );
      return false;
    } else if (operation === changePasswordLabel) {
      if (!oldPassword) {
        setButtonLoading(false);
        openNotification("info", passwordEmptyMsg);
        return false;
      }
    }
    return true;
  };

  async function onSubmitClick() {
    setErrorDescription('');
    if (!!validatePassword()) {
      await ApiService.post(
        operation === forgotPasswordLabel
          ? `${base_url}${Urls.resetPassword}`
          : `${base_url}${Urls.changePassword}`,
        operation === forgotPasswordLabel
          ? {
            new_password: resetPassword,
            username: userState.userInfo.user_name,
            sessionToken: getSessionToken(),
          }
          : {
            oldPassword: oldPassword,
            newPassword: newPassword,
            username: userState.userInfo.user_name,
            passcode: "",
            sessionToken: getSessionToken(),
          }
      ).then((response) => {
        console.log(response)
        setButtonLoading(false);
        if (!!verifyErrorAndShowMessage(response, apiChangePasswordErrorMsg)) {

          if (!!response.hasOwnProperty("status") && response.status === "SUCCESS") {
            openNotification(
              "success",
              userState.selectedOperation === forgotPasswordLabel
                ? forgotPasswordSuccessMsg
                : changePasswordSuccessMsg
            );
            navigate(confirmationRoute, { state: confirmationRoute });
          } else {
            if (!!response.hasOwnProperty("message")) {
              setErrorDescription(response.message);
              openNotification(
                "info",
                response.message
              );
            }
            else {
              openNotification(
                "error",
                userState.selectedOperation === forgotPasswordLabel
                  ? apiResetPasswordErrorMsg
                  : apiChangePasswordErrorMsg
              );
            }
          }
        } else {
          setErrorDescription(getAlertSummary(response) as string);
        }
      }).catch(error => {
        setButtonLoading(false);
        setErrorDescription(APITimeoutErrorMsg);
      }).finally(() => {
        setButtonLoading(false);
      });
    }
  }
  const content = {
    ops: [
      { insert: brandingDetails?.instructions }
    ],
  };
  return (
    <Skeleton loading={loading}>
      <div className="recovery-homepage-container">
        {!!brandingDetails?.logo ?
          <div className={"recovery-option-logo recovery-option-div"} style={{
            height: "100px",
            display: "flex",
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center"
          }}>
            <img alt="Branding" src={`${brandingDetails?.logo}`} style={{
              maxWidth: "90%",
              height: "80%",
            }} />        </div> : ""}

        <div className={"factor-option-div"}>
          <Divider style={{ border: "1px", borderColor: "#d8d8d8" }}>
            {" "}
            <div
              className={
                "recovery-option-beacon-container factor-authorize-div"
              }
            >
              <div className={"recovery-option-beacon-logo"}>
                <img
                  className={"recovery-option-beacon-image"}
                  alt="Branding"
                  src={"../../human.png"}
                ></img>{" "}
              </div>
            </div>
          </Divider>
        </div>
        {!!errorDescription ? <div style={{ position: "relative", margin: "20px" }}>
          <Alert type="warning" showIcon
            description={errorDescription} ></Alert>
        </div> : ""}

        <div className={"recovery-option-div recovery-option-branding"}>
          <h2>{operation}</h2>
        </div>

        <div className={"recovery-option-fields-container"}>
          <div className={"recovery-option-fields"}>

            {operation === "Change Password" ? (
              <div className={"recovery-option-username recovery-option-div"}>
                <div style={{ display: "flex" }}>Old password</div><Password
                  size="large"
                  autoFocus={operation === "Change Password" ? true : false}
                  placeholder="Old Password"
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                />
              </div>
            ) : (
              ""
            )}
            <div className={"recovery-option-username recovery-option-div"}>
              <div style={{ display: "flex" }}>New Password</div><Password
                size="large"
                autoFocus={operation === "Change Password" ? false : true}
                placeholder={"Enter new password"}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
            </div>
            <div className={"recovery-option-username recovery-option-div"}>
              <div style={{ display: "flex" }}>Re-enter password</div><Password
                size="large"
                placeholder="Repeat Password"
                onChange={(e) => {
                  setResetPassword(e.target.value);
                }}
              />
            </div>
            <div className="recovery-option-button recovery-option-div">
              <Button type="primary" size="large" onClick={() => {
                setButtonLoading(true)
                onSubmitClick();
              }}
                loading={buttonLoading}>
                {operation === "Forgot Password" ? "Reset Password" : operation}
              </Button>
            </div>
            <div style={{ paddingTop: "15px", paddingBottom: "10px" }}>
              <nav>
                <Link to={recoveryHomePageRoute} onClick={() => {
                  clearSessionStorage();
                }}>
                  <u>Back to recovery</u>
                </Link>
              </nav>
            </div>
            {!!brandingDetails?.instructions ? <div>
              <div style={{
                display: "flex",
                color: "grey"
              }}>
                <div>Help/Instructions:</div>
              </div>
              <div style={{
                display: "flex",
                color: "grey"
              }}>
                <div><RichTextViewer content={content} /></div>
              </div>
            </div> : ""}
          </div>
        </div>
      </div>
    </Skeleton>
  );
}
