import { Alert, Button, Divider, Skeleton } from "antd";
import Password from "antd/lib/input/Password";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiService from "../../Api.service";
import Urls, {
  accountUnlockSuccessMsg,
  answerEmptyMsg,
  answerVerifySuccesMsg,
  APITimeoutErrorMsg,
  apiValidateAnswerErrorMsg,
  base_url,
  confirmationRoute,
  recoveryHomePageRoute,
  recoveryOptionRoute,
  unlockAccountLabel
} from "../../ApiUtils";
import UserContext from "../../features/userContext";
import {
  clearSessionStorage,
  componentMountCheck,
  getAlertSummary,
  getSessionToken,
  saveSessionToken, verifyErrorAndShowMessage
} from "../../helpers/helper.service";
import {
  Branding,
  validateAnswerPayload
} from "../../interfaces/api.interface";
import ssprService from "../../services/sspr.service";
import { openNotification } from "../Common/Notification";
import RichTextViewer from "../Common/RichTextViewer";
import "./RecoveryQuestion.css";

export default function RecoveryQuestion() {
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [userState] = useContext(UserContext);
  const [brandingDetails, setBrandingDetails] = useState<Branding>();
  const [errorDescription, setErrorDescription] = useState("");
  const [loading, setLoading] = useState(true);

  async function handleVerifyClick() {
    if (!recoveryAnswer) {
      openNotification("info", answerEmptyMsg);
      return
    }
    setButtonLoading(true);
    let payload: validateAnswerPayload = {
      answer: !!recoveryAnswer ? recoveryAnswer : "",
      username: userState.userInfo.user_name,
      sessionToken: getSessionToken(),
    };
    await ApiService.post(`${base_url}${Urls.validateAnswer}`, payload).then(
      (response) => {
        setButtonLoading(false);
        document.getElementById("codeTextField")?.focus();
        if (!!verifyErrorAndShowMessage(response, apiValidateAnswerErrorMsg)) {
          if (
            response.hasOwnProperty("recoveryType") &&
            response.hasOwnProperty("status") &&
            response.status === "PASSWORD_RESET"
          ) {
            saveSessionToken(response.sessionToken);
            openNotification("success", answerVerifySuccesMsg);
            if (userState.selectedOperation === unlockAccountLabel) {
              // setCurrentPage1(confirmationRoute);
              navigate(confirmationRoute, { state: confirmationRoute });
            } else {
              // setCurrentPage1(recoveryOptionRoute);
              navigate(recoveryOptionRoute, { state: recoveryOptionRoute });
            }
          } else if (
            response.hasOwnProperty("recoveryType") &&
            response.recoveryType === "UNLOCK"
          ) {
            openNotification("success", accountUnlockSuccessMsg);
            // clearSessionStorage();
            // setCurrentPage1(confirmationRoute);
            navigate(confirmationRoute, { state: confirmationRoute });
          } else if (!!response.hasOwnProperty("factorResult") && !!response.factorResult) {
            openNotification("info", response.factorResult);
          } else {
            openNotification("info", apiValidateAnswerErrorMsg);
          }
        } else {
          document.getElementById("codeTextField")?.focus();
          setErrorDescription(getAlertSummary(response) as string);
        }
      }
    ).catch(error => {
      setErrorDescription(APITimeoutErrorMsg);
      setButtonLoading(false);
    }).finally(() => {
      setButtonLoading(false);
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [imageBeaconSrc, setImageBeaconSrc] = useState(
    "../../question_38x38.8453db9ea592a3015add7fb268491a3b.png"
  );
  const [userInfo, setUserInfo] = useState({
    recovery_question: "",
    user_name: "",
  });
  const location = useLocation();
  const [recoveryAnswer, setRecoveryAnswer] = useState("");
  useEffect(() => {
    componentMountCheck(location.state as string);
    setUserInfo(userState.userInfo);
    setBrandingDetails(ssprService.getBrandingDetails());
    if (!!ssprService.getBrandingDetails() && !!ssprService.getBrandingDetails().background_image)
      document.body.style.backgroundImage = `url(${ssprService.getBrandingDetails().background_image}?v=2)`;
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const content = {
    ops: [
      { insert: brandingDetails?.instructions }
    ],
  };
  return (
    <Skeleton loading={loading}>

      <div className="recovery-homepage-container">
        {!!brandingDetails?.logo ?
          <div className={"recoveryquestion-authorize-logo factor-authorize-div"} style={{
            height: "100px",
            display: "flex",
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center"
          }}>
            <img alt="Branding" src={`${brandingDetails?.logo}`} style={{
              maxWidth: "90%",
              height: "80%",
            }} />
          </div> : ""}
        <div className={"recoveryquestion-authorize-div"}>
          <Divider style={{ border: "1px", borderColor: "#d8d8d8" }}>
            {" "}
            <div
              className={
                "recoveryquestion-authorize-beacon-container recoveryquestion-authorize-div"
              }
            >
              <div className={"recoveryquestion-authorize-beacon-logo"}>
                <img
                  className={"recoveryquestion-authorize-beacon-image"}
                  alt="Branding"
                  src={imageBeaconSrc}
                ></img>{" "}
              </div>
            </div>
          </Divider>
        </div>
        {!!errorDescription ? <div style={{ position: "relative", margin: "20px" }}>
          <Alert type="warning" showIcon
            description={errorDescription} ></Alert>
        </div> : ""}
        <div className={"recoveryquestion-authorize-fields-container"}>
          <div
            className={
              "recoveryquestion-homepage-caption recoveryquestion-homepage-div"
            }
          >
            <h3 className={"recoveryquestion-header-help"}>
              {userState.selectedOperation}
            </h3>
          </div>

          <div
            className={
              "recoveryquestion-homepage-caption recoveryquestion-homepage-div"
            }
          >
            <h3 className={""}>Recovery Question</h3>
          </div>
          <div className={"factor-homepage-caption factor-homepage-div"}>
            {userInfo?.recovery_question}
          </div>

          <div className={"recovery-homepage-username recovery-homepage-div"}>
            <Password
              autoFocus={true}
              id="codeTextField"
              onChange={(e) => {
                setRecoveryAnswer(e.target.value);
              }}
              placeholder="Answer?"
              size="large"
            />
          </div>
          <div className="factory-homepage-button factory-homepage-div">
            <Button loading={buttonLoading}
              className={""}
              type="primary"
              size="large"
              onClick={handleVerifyClick}
            >
              Verify
            </Button>
          </div>
          <div style={{ paddingTop: "15px", paddingBottom: "10px" }}>
            <nav>
              <Link to={recoveryHomePageRoute} onClick={() => {
                clearSessionStorage();
              }}>
                <u>Back to recovery</u>
              </Link>
            </nav>
            {!!brandingDetails?.instructions ? <div>
              <div style={{
                display: "flex",
                color: "grey"
              }}>
                <div>Help/Instructions:</div>
              </div>
              <div style={{
                display: "flex",
                color: "grey"
              }}>
                <div><RichTextViewer content={content} /></div>
              </div>
            </div> : ""}
          </div>
        </div>
      </div>
    </Skeleton>
  );
}
