export interface UserAuthorizeFactors {
  factorType: string;
  factorProvider: string;
  factorStatus: string;
  hint: string;
}

export interface FactorsAuthorize {
  factorTitle: string;
  factorType: string;
  factorProvider: string;
  factorStatus: string;
  question: string;
  logoSrc: string;
  beaconLogoSrc: string;
  sendButtonLabel: string;
  sendButtonVisibility: boolean;
  verifyButtonLabel: string;
  verifyButtonVisibility: boolean;
  itemTitle: string;
}
export interface userInfoResponse {
  factor: UserAuthorizeFactors[];
  user_name: string;
  recovery_question: string;
  sessionToken: any | string;
  config: ISSPRConfig;
  show_unlock_account: boolean;
  show_forgot_password: boolean;
  show_change_password: boolean;
  currentStep: string;
}

export interface ISSPRConfig {
  factors_ui_display: string;
  default_factor_to_show: string;
}

export interface IUserSessionInfo {
  userInfo: userInfoResponse;
  selectedOperation: string;
}

export interface validateAnswerPayload {
  answer: string;
  username: string;
  sessionToken: string | null;
}

export interface forgotPasswordPayload {
  username: string;
  actionType: string;
  factor_type: string;
  factor_provider: string;
  one_time_code: string;
  answer: string;
  sessionToken: string | null;
}

export interface validateChallengePayload {
  username: string;
  passCode: string;
  sessionToken: string | any;
  factor_type: string|undefined;
  factor_provider: string|undefined;
}

export interface resetPasswordPayload {
  new_password: string;
  username: string;
  sessionToken: string | null;
}

export interface changePasswordPayload {
  oldPassword: string;
  newPassword: string;
  username: string;
  passcode: string;
  sessionToken: string;
}

export interface unlockAccountPayload {
  username: string;
  factor_type: string;
  one_time_code: string;
  answer: string;
  factor_provider: string;
  sessionToken: string | null;
}

export interface unlockAccountStepTwoPayload {
  username: string;
  factor_type: string;
  passCode: string;
  factor_provider: string;
  sessionToken: string | null;
}

export interface check_reset_passwordPayload {
  username: string;
  user_id: string;
}

export interface ErrorResponse {
  errorCode: string;
  errorResponse: string;
}

export interface AuthorizedFactors {
  title: string;
  itemlogo: string;
  beaconLogoSrc: string;
}

export interface Branding {
  logo: string;
  instructions: string;
  branding_title: string;
  background_image: string;
  operations: {
    show_change_password: boolean;
    show_forgot_password: boolean;
    show_unlock_account: boolean;
  }
}

export interface TecproofVerification{
  sessionToken:string|null;
  email:string;
  factor_type:string;
  factor_provider:string;
  actionType:string;
}

export interface TecproofVerificationResult{
  sessionToken: string | null;
  email:string;
  factor_type:string;
  factor_provider:string;
  actionType:string;
}

export const SSPRSupportedRecoveryFactors = [
  {
    factorType: "token:hardware",
    provider: "YUBICO",
    factor_display: "Yubico Authentication",
    key: "TOKEN_HARDWARE_TOTP_YUBICO",
    status: "ACTIVE",
  },
  {
    factorType: "token:hotp",
    provider: "CUSTOM",
    factor_display: "Feitian Hardware Token",
    key: "TOKEN_HOTP_CUSTOM",
    status: "ACTIVE",
  },
  {
    factorType: "web",
    provider: "DUO",
    factor_display: "DUO Security",
    key: "WEB_DUO",
    status: "ACTIVE",
  },
  {
    factorType: "push",
    provider: "OKTA",
    factor_display: "OKTA Verify With Push",
    key: "PUSH",
    status: "ACTIVE",
  },
  {
    factorType: "call",
    provider: "OKTA",
    factor_display: "Voice Call Authentication",
    key: "CALL",
    status: "ACTIVE",
  },
  {
    factorType: "voice",
    provider: "OKTA",
    factor_display: "Voice Call Authentication",
    key: "VOICE",
    status: "ACTIVE",
  },
  {
    factorType: "sms",
    provider: "OKTA",
    factor_display: "SMS Authentication",
    key: "SMS",
    status: "ACTIVE",
  },
  {
    factorType: "question",
    provider: "OKTA",
    factor_display: "Security Question",
    key: "QUESTION",
    status: "ACTIVE",
  },
  {
    factorType: "token:software:totp",
    provider: "OKTA",
    factor_display: "okta Verify",
    key: "TOKEN_SOFTWARE_TOTP_OKTA",
    status: "ACTIVE",
  },
  {
    factorType: "token:software:totp",
    provider: "GOOGLE",
    factor_display: "Google Authenticator",
    key: "TOKEN_SOFTWARE_TOTP_GOOGLE",
    status: "ACTIVE",
  },
  {
    factorType: "email",
    provider: "OKTA",
    factor_display: "Email Authentication",
    key: "EMAIL",
    status: "ACTIVE",
  },
  {
    factorType: "face_auth",
    provider: "CREDENTI",
    factor_display: "Credenti Face Authentication",
    key: "FACE_AUTH",
    status: "ACTIVE",
  }
];

export const DUOFactorList: UserAuthorizeFactors[] = [
  {
    factorProvider: "DUO",
    factorStatus: "ACTIVE",
    factorType: "token:software:totp",
    hint: "",
  }, {
    factorProvider: "DUO",
    factorStatus: "ACTIVE",
    factorType: "sms",
    hint: "",
  }, {
    factorProvider: "DUO",
    factorStatus: "ACTIVE",
    factorType: "push",
    hint: "",
  },
];
