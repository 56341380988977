import React, { createContext } from "react";
import { IUserSessionInfo } from "../interfaces/api.interface";

type IUserInfoContext = [
  IUserSessionInfo,
  React.Dispatch<React.SetStateAction<IUserSessionInfo>>
];

export const UserContext = createContext<IUserInfoContext>([
  {
    selectedOperation: "",
    userInfo: {
      user_name: "",
      factor: [],
      
      recovery_question: "",
  
      sessionToken: "",
  
      config: { factors_ui_display: "", default_factor_to_show: "" },

      show_change_password: false,
      show_forgot_password: false,
      show_unlock_account: false,
      currentStep: ""
    },
  },
  () => null,
]);

export default UserContext;
