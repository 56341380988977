import { useEffect, useState } from "react";
import { IUserSessionInfo } from "../interfaces/api.interface";
import UserContext from "./userContext";

const UserState = (props: any) => {
  let userinfo = sessionStorage.getItem("user-info");
  const userInfo: IUserSessionInfo = !!userinfo
    ? JSON.parse(userinfo)
    : {
      selectedOperation: "",
      userInfo: {
        email: "",
        factor: [],
        login: "",
        recovery_question: "",
        user_name: "",
        show_change_password: false,
        show_forgot_password: false,
        show_unlock_account: false,
        currentStep: ""
      },
    };
  const [userState, setUserState] = useState<IUserSessionInfo>(userInfo);
  if (window.location.pathname === "/") {
    sessionStorage.clear();
  }
  if (!userState.selectedOperation && window.location.pathname !== "/") {
    window.location.href = '/'
  }

  useEffect(() => {
    if (!!userState && !!userState.selectedOperation)
      sessionStorage.setItem("user-info", JSON.stringify(userState));
  }, [userState]);

  return (
    <UserContext.Provider value={[userState, setUserState]}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
