import { Branding } from "../interfaces/api.interface";

export default {

    getBrandingDetails<Branding>() {
        return JSON.parse(sessionStorage.getItem('branding-details')!);
    },

    setBrandingDetails(details: string) {
        return sessionStorage.setItem('branding-details', JSON.stringify(details));

    }

}