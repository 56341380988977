import React, { useEffect, useRef } from 'react';
import Quill from 'quill';

const RichTextViewer = ({ content }: any) => {
    const editorRef = useRef(null);

    useEffect(() => {
        if (editorRef.current) {
            const quill = new Quill(editorRef.current, {
                readOnly: true,
            });
            quill.setContents(content);
        }
    }, [content]);

    return <div ref={editorRef} />;
};

export default RichTextViewer;
