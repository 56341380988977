import { Button } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { recoveryHomePageRoute } from "../../ApiUtils";


const NoPortalAccess = () => {
    useEffect(() => {
        document.body.style.backgroundImage = '';
        sessionStorage.clear();
        localStorage.clear();
    }, [])
    const navigate = useNavigate();
    return (
        <div style={{ textAlign: 'center', paddingTop: '100px' }}>
            <img alt="permission-denied" src="../../PermissionDenied.png" />
            <div style={{ textAlign: 'center', fontSize: 'x-large', fontWeight: '600' }}>
                Something went wrong.
            </div>
            <div style={{ textAlign: 'center', fontSize: 'large', fontWeight: '400', paddingBottom: "10px" }}>
                Try to refresh this page or contact administrator if the problem persists.
            </div>
            <Button type="primary" size="large" style={{ width: "10%" }}
                onClick={() => navigate(recoveryHomePageRoute, { state: recoveryHomePageRoute })}
            >
                Refresh
            </Button>
        </div>
    )
}

export default NoPortalAccess;
