import { getPushSessionToken } from "./helpers/helper.service";

export const base_url = process.env.REACT_APP_API_URL;

// export const base_url = "http://localhost:5000/recovery";
// export const unify_url = process.env.REACT_APP_UNIFY_API_URL

export function getAccessToken() {
  const okta_token_storage = localStorage.getItem("session");
  // alert(okta_token_storage);
  return JSON.parse(okta_token_storage ? okta_token_storage : "{}")?.accessToken
    ?.accessToken;
  // return '.eyJ1aWQiOiIzYmZjOTdkNGNjOTQ0ODgzOGVlODZiYzc3Yjc2M2FhYSIsInVzZXJuYW1lIjoic3Nwci50ZXN0MSJ9.Ys8rFA.g4lLVJyYjewgbCRpfb0A9BUwjwk'
}

export function getUserInfoFromStorage() {
  const userInfo = localStorage.getItem("user-info");
  return JSON.parse(!!userInfo ? userInfo : "{}");
}
export function getCredentiTokenHeaders() {
  return {
    "Content-Type": "application/json",
    // Origin: "http://localhost:3000",
  };
}

const Urls = {
  // app urls
  userInfo: (username: string, action: string) =>
    `/api/v1/recovery/getUserInfo?username=${username}&action=${action}`,
  forgotPassword: `/api/v1/recovery/forgot_password`,
  validateChallenge: `/api/v1/recovery/validateChallenge`,
  resetPassword: `/api/v1/recovery/resetPassword`,
  changePassword: `/api/v1/recovery/changePassword`,
  unlockAccount: `/api/v1/recovery/unlockAccount`,
  pollForPushSuccess: (username: string) =>
    `/api/v1/recovery/pollForPushSuccess?username=${username}&sessionToken=${getPushSessionToken()}`,
  unlockAccount_stepTwo: `/api/v1/recovery/unlockAccount_stepTwo`,
  validateAnswer: `/api/v1/recovery/validate_answer`,
  check_reset_password: `/api/v1/recovery/rotateUserPassword`,
  branding: `/api/v1/recovery/branding`
};

//get user info response keys
export const userEmailKey = "email";
export const userLoginKey = "login";
export const userUsernameKey = "user_name";
export const userRecoveryQuestionKey = "recovery_question";
export const sessionTokenKey = "sessionToken";
export const mobilePhoneKey = "mobilePhone";
export const callPhoneKey = "callPhone";
//header
export const accountRecoveryHeaderLabel = "Account Recovery";

export const forgotPasswordLabel = "Forgot Password";
export const changePasswordLabel = "Change Password";
export const unlockAccountLabel = "Unlock Account";

//factors

export const googleFactorProvider = "Google";
export const smsFactorType = "sms";
export const smsFactorProvider = "OKTA";
export const emailFactorType = "email";
export const emailFactorProvider = "OKTA";
export const callFactorType = "call";
export const voiceFactorType = "voice";
export const callFactorProvider = "OKTA";
export const googleTOTPFactorType = "token:software:totp";
export const oktaTOTPFactorType = "token:software:totp";
export const oktaTOTPFactorProvider = "OKTA";
export const questionFactorType = "question";
export const questionFactorProvider = "OKTA";
export const MFAQuestionKey = "MFA Question";
export const oktaPushFactorType = "push";
export const oktaPushFactorProvider = "OKTA";
export const yubicoTOTPHardwareType = "token:hardware";
export const yubicoTOTPHardwareProvider = "YUBICO";
export const RSATokenFactorType = 'token';
export const RSATokenFactorProvider = 'RSA';
export const DUOTOTPFactorType = "token:software:totp";
export const DUOTOTPFactorProvider = "DUO";
export const DUOPushFactorType = "push";
export const DUOPushFactorProvider = "DUO";
export const DUOFactorType = "web";
export const DUOFactorProvider = "DUO";
export const DUOSMSFactorType = 'sms';
export const HOTPFactorType = "token:hotp";
export const HOTPFactorProvider = "Custom";
export const CREDENTIFactorProvider = "CREDENTI";
export const FACE_AUTHFactoryType = "face_auth";

//navigate routes
export const factorsAuthorizeRoute = "/FactorAuthorize";
export const recoveryQuestionRoute = "/RecoveryQuestion";
export const recoveryOptionRoute = "/RecoveryOption";
export const confirmationRoute = "/Confirmation";
export const recoveryHomePageRoute = "/";
export const noPortalAccess = "/NoPortalAccess"

///error messages
export const invalidUsernameMsg = "Enter Valid Username";
export const apigetUserDetailsErrorMsg =
  "An Error Has Occured While Getting User Info";
export const apiforgotPasswordErrorMsg =
  "An Error Has Occured With Calling API Service";
export const apiValidateChallengeErrorMsg =
  "An Error Has Occured With Calling API Service";
export const apiResetPasswordErrorMsg =
  "An Error Has Occured With Calling API Service";
export const apiChangePasswordErrorMsg =
  "An Error Has Occured With Calling API Service";
export const apiUnlockAccountErrorMsg =
  "An Error Has Occured With Calling API Service";
export const apiPollForSuccessErrorMsg =
  "An Error Has Occured With Calling API Service";
export const apiUnlockAccountStepTwoErrorMsg =
  "An Error Has Occured With Calling API Service";
export const apiValidateAnswerErrorMsg =
  "An Error Has Occured With Calling API Service";
export const genericErrorMsg =
  "Something went wrong. Please try again";
export const APITimeoutErrorMsg =
  "An error has been occurred. Please contact administrator";

//success messages
export const forgotPasswordSuccessMsg = "Password Reset Successful";
export const changePasswordSuccessMsg = "Password Changed";
export const pushSentSuccessMsg = "Push Sent Successfully";
export const passcodeVerifySuccesMsg = "Verified Successfully";
export const answerVerifySuccesMsg = "Answer Verified";
export const accountUnlockSuccessMsg = "Account Unlocked";
export const codeSentSuccessMsg = "Code Sent Successfully";
export const pushApprovedMsg = "Push Approved";
export const pushTimeoutMsg = "Push Timed Out"

export const passcodeEmptyMsg = "Passcode must be provided";
export const passwordEmptyMsg = "Password must be provided";
export const answerEmptyMsg = "Answer must be provided";





export const factorImages38_38 = [
  { "factorType": "push", "factorProvider": "OKTA", "imageSrc": "../../oktaVerify_38x38.ec588b98b16b2ddd8a0256ffbc3bab88.png" },
  {
    "factorType": "email", "factorProvider": "OKTA", "imageSrc": "../../email_38x38.b7ac950da2ba64f5886b21c9f97c7cc8.png",
  }, {
    "factorType": "call", "factorProvider": "OKTA", "imageSrc": "../../voicecall_38x38.98ee4e6eac4e482dc8b2db9eadafb031.png",
  }, {
    "factorType": "question", "factorProvider": "OKTA", "imageSrc": "../../question_38x38.8453db9ea592a3015add7fb268491a3b.png",
  }, {
    "factorType": "sms", "factorProvider": "OKTA", "imageSrc": "../../sms_38x38.4661fd6ccaded3a68b1803ac32b26592.png",
  }, {
    "factorType": "token:software:totp", "factorProvider": "OKTA", "imageSrc": "../../oktaVerify_38x38.ec588b98b16b2ddd8a0256ffbc3bab88.png",
  }, {
    "factorType": "token:software:totp", "factorProvider": "GOOGLE", "imageSrc": "../../googleAuth_38x38.750e8cb4f65b1d8f3a844baa0ac99454.png",
  }, {
    "factorType": "token:hardware", "factorProvider": "YUBICO", "imageSrc": "../../yubico.png",
  }, {
    "factorType": "token:software:totp-OKTA", "factorProvider": "RSA", "imageSrc": "../../rsa_38x38.44b8b7f07f63b71ca53c6164d07d933d.png"
  }, {
    "factorType": "web", "factorProvider": "DUO", "imageSrc": "../../duo_38x38.9944c099fa2327e9e4da2b33c9516593.png",
  },
  { "factorType": "push", "factorProvider": "DUO", "imageSrc": "../../duo_38x38.9944c099fa2327e9e4da2b33c9516593.png", },
  { "factorType": "sms", "factorProvider": "DUO", "imageSrc": "../../duo_38x38.9944c099fa2327e9e4da2b33c9516593.png", },
  { "factorType": "token:software:totp", "factorProvider": "DUO", "imageSrc": "../../duo_38x38.9944c099fa2327e9e4da2b33c9516593.png", },
  { "factorType": HOTPFactorType, "factorProvider": HOTPFactorProvider, "imageSrc": "../../hotpFactor_38x38.png", },
  {"factorType":FACE_AUTHFactoryType, "factorProvider":CREDENTIFactorProvider, "imageSrc":"../../credenti-favicon.png"}
]

export default Urls;
