import { factorImages38_38, genericErrorMsg } from "../ApiUtils";
import { openNotification } from "../Components/Common/Notification";

export const verifyErrorAndShowMessage = (input: any, errorMessage: string) => {

  if (input.hasOwnProperty('sessionToken'))
    saveSessionToken(input.sessionToken);
  if (!input) {
    openNotification("info", errorMessage);
    return false;
  } else if (input.hasOwnProperty("errorCode")) {
    openNotification("info", input.errorSummary);
    return false;
  } else if (input.hasOwnProperty("error")) {
    openNotification("info", input.error);
    return false;
    // } else if (input.hasOwnProperty("message")) {
    //   // openNotification("info", input.message);
    //   return false;
  }
  return true;
};

export const getSessionToken = () => {
  // console.log("session token  ", sessionStorage.getItem("sessionToken"));
  return !!sessionStorage.getItem("sessionToken")
    ? sessionStorage.getItem("sessionToken")
    : null;
};

export const saveSessionToken = (sessionToken: string) => {
  sessionStorage.setItem("sessionToken", sessionToken);
  // return sessionStorage.setItem("sessionToken", sessionToken);
};

export const setPushSessionToken = (sessionToken: string) => {
  sessionStorage.setItem("pushSessionToken", sessionToken);
}

export const getFactorImage = (factorType: string, factorProvider: string) => {
  let imgSrc = ""
  factorImages38_38.forEach(element => {
    if (element.factorType.toLowerCase() === factorType.toLowerCase() && element.factorProvider.toLowerCase() === factorProvider.toLowerCase()) {
      imgSrc = element.imageSrc;
    }
  })
  return imgSrc
}

export const getPushSessionToken = () => {
  return sessionStorage.getItem("pushSessionToken");
}

export const clearSessionStorage = () => {
  sessionStorage.clear();
};

export const setCurrentPage = (pathname: string) => {
  sessionStorage.setItem('page-info', pathname);
}

export const getCurrentPage = () => {
  return sessionStorage.getItem('page-info') as string;
}

export const componentMountCheck = (route?: string) => {
  if (route !== window.location.pathname) {
    openNotification("info", genericErrorMsg);
    window.location.href = "/";
  }
}

export const getAlertSummary = (errorResponse: any) => {

  var errorMsg = "";
  if (!!errorResponse.hasOwnProperty("errorCauses") && errorResponse["errorCauses"].length > 0) {

    for (var i = 0; i < errorResponse["errorCauses"].length; i++) {
      errorMsg += errorResponse["errorCauses"][i]['errorSummary'];
    }
    return errorMsg;
  }

  if (!errorMsg)
    return errorResponse.errorSummary;
}


export const setXRequestId = (requestId: string) => {
  sessionStorage.setItem("X-Request-Id", requestId)
}

export const getXRequestId = () => {
  sessionStorage.getItem("X-Request-Id")
}