import { Button, Divider, Skeleton } from "antd";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  accountUnlockSuccessMsg,
  changePasswordLabel,
  changePasswordSuccessMsg,
  forgotPasswordLabel,
  forgotPasswordSuccessMsg,
  recoveryHomePageRoute
} from "../../ApiUtils";
import UserContext from "../../features/userContext";
import { clearSessionStorage, componentMountCheck } from "../../helpers/helper.service";
import { Branding } from "../../interfaces/api.interface";
import ssprService from "../../services/sspr.service";
import "./Confirmation.css";

export default function Confirmation() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [message, setMessage] = useState("");
  const [brandingDetails, setBrandingDetails] = useState<Branding>()
  const location=useLocation();
  const [loading, setLoading] = useState(true);
  const [userState] = useContext(UserContext);
  useEffect(() => {
    componentMountCheck(location.state as string);
    setBrandingDetails(ssprService.getBrandingDetails());
    if(!!ssprService.getBrandingDetails() && !!ssprService.getBrandingDetails().background_image)
      document.body.style.backgroundImage = `url(${ssprService.getBrandingDetails().background_image}?v=4)`;
    setMessage(
      userState.selectedOperation === forgotPasswordLabel
        ? forgotPasswordSuccessMsg
        : userState.selectedOperation === changePasswordLabel
          ? changePasswordSuccessMsg
          : accountUnlockSuccessMsg
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  return (
    <Skeleton loading={loading}>
      <div className="confirmation-container">
      {!!brandingDetails?.logo ?  <div className={"confirmation-logo confirmation-div"} style={{
          height: "100px",
          display: "flex",
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center"
        }}>

          <img alt="Branding" src={`${brandingDetails?.logo}`} style={{
            maxWidth: "90%",
            height: "80%",
          }} />
        </div> : "" }

        <div className={"factor-option-div"}>
          <Divider style={{ border: "1px", borderColor: "#d8d8d8" }}>
            <div
              className={"confirmation-beacon-container factor-authorize-div"}
            >
              <div className={"confirmation-beacon-logo"}>
                <img
                  className={"confirmation-beacon-image"}
                  alt="Branding"
                  src={"../../human.png"}
                ></img>{" "}
              </div>
            </div>
          </Divider>
        </div>

        <div className={"confirmation-div confirmation-branding"}>
          <h2>
            {message}
          </h2>
        </div>
        <div className={"confirmation-fields-container"}>
          <div className={"confirmation-fields"}>
            <div className="confirmation-button confirmation-div">
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  clearSessionStorage();
                  // setCurrentPage1(recoveryHomePageRoute);
                  window.close();
                  navigate(recoveryHomePageRoute, { state: recoveryHomePageRoute });
                }}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Skeleton>
  );
}
