import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// import "antd/dist/antd.css";
import "antd/dist/antd.min.css";
import './App.css';
import {
  confirmationRoute,
  factorsAuthorizeRoute,
  recoveryOptionRoute,
  recoveryQuestionRoute
} from "./ApiUtils";
import Confirmation from "./Components/Common/Confirmation";
import NoPortalAccess from "./Components/Common/NoPortalAccess";
import FactorAuthorize from "./Components/Factors/FactorAuthorize";
import RecoveryHomePage from "./Components/Recovery/RecoveryHomePage";
import RecoveryOption from "./Components/Recovery/RecoveryOption";
import RecoveryQuestion from "./Components/RecoveryQuestion/RecoveryQuestion";
import UserState from "./features/userState";



function App() {

  useEffect(() => {

    if (process.env.REACT_APP_ENV === 'test' || process.env.REACT_APP_ENV === 'prod') {
      document.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      });
      document.addEventListener("keydown", function (event) {
        var key = event.key;
        //developer tools
        if (event.key === 'Meta' || key === 'F12' || (event.ctrlKey && event.shiftKey && key === 'I') ||
          (event.ctrlKey && event.shiftKey && key === 'J') ||
          (event.ctrlKey && event.shiftKey && key === 'C') ||
          (event.ctrlKey && (key === 'C' || key === 'c')) ||
          (event.ctrlKey && (key === 'P' || key === 'p')) ||
          (event.ctrlKey && (key === 'O' || key === 'o')) ||
          (event.ctrlKey && (key === 'U' || key === 'u')) ||
          (event.ctrlKey && (key === 'S' || key === 's')) ||
          (event.ctrlKey && (key === 'R' || key === 'r')))
          event.preventDefault();
      });
    }
  }, [])

 
  return (
    
      <UserState>
        <BrowserRouter>
          <Routes>
            <Route
              path={factorsAuthorizeRoute}
              element={<FactorAuthorize />}
            ></Route>
            <Route
              path={recoveryQuestionRoute}
              element={<RecoveryQuestion />}
            ></Route>
            <Route
              path={recoveryOptionRoute}
              element={<RecoveryOption />}
            ></Route>
            <Route path={confirmationRoute} element={<Confirmation />}></Route>
            <Route path="/" element={<RecoveryHomePage />}></Route>
            <Route path="*" element={<NoPortalAccess />} />
          </Routes>
        </BrowserRouter>
      </UserState>
      
  );
}

export default App;
