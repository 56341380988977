import { Alert, Button, Divider, Input, Skeleton } from "antd";

import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiService from "../../Api.service";


import Urls, {
  apigetUserDetailsErrorMsg,
  base_url,
  changePasswordLabel,
  factorsAuthorizeRoute,
  forgotPasswordLabel, invalidUsernameMsg,
  noPortalAccess,
  sessionTokenKey,
  unlockAccountLabel,
  userRecoveryQuestionKey,
  userUsernameKey
} from "../../ApiUtils";
import UserContext from "../../features/userContext";
import {
  saveSessionToken, verifyErrorAndShowMessage
} from "../../helpers/helper.service";
import { Branding, SSPRSupportedRecoveryFactors, userInfoResponse } from "../../interfaces/api.interface";
import ssprService from "../../services/sspr.service";
import { openNotification } from "../Common/Notification";
import RichTextViewer from "../Common/RichTextViewer";
import "./RecoveryHomePage.css";

export default function RecoveryHomePage() {


  let navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [imageBeaconSrc, setImageBeaconSrc] = useState("../../human.png");
  const [userState, setUserState] = useContext(UserContext);

  const validateUsername = () => {
    let pattern = /(<*?>)/;
    return !!userName && !pattern.test(userName);
  };
  const [loading, setLoading] = useState(true);
  const [selectedaction, setSelectedAction] = useState("");
  // const [forgotPasswordSpinLoad, setForgotPasswordSpinLoad] = useState(false);
  // const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [brandingDetails, setBrandingDetails] = useState<Branding>();
  const [errorDescription, setErrorDescription] = useState("");
  useEffect(() => {
    document.getElementById("user-name")?.focus();
    // setCurrentPage(window.location.pathname);
    ApiService.get(`${base_url}${Urls.branding}`).then(response => {
      if (response.hasOwnProperty("errorCode")) {
        navigate(noPortalAccess, { state: noPortalAccess });
      } else {
        ssprService.setBrandingDetails(response);
        setBrandingDetails(response);

        if (!!response.background_image)
          document.body.style.backgroundImage = `url(${response.background_image}?v=9)`;
        setLoading(false);
      }
    }).catch(error => {
      console.log(error);
      navigate(noPortalAccess, { state: noPortalAccess });
    });
  }, []);
  const [buttonLoadings, setButtonLoadings] = useState<boolean[]>([false, false, false]);
  const content = {
    ops: [
      { insert: brandingDetails?.instructions }
    ],
  };

  const setButtonLoading = (index: number) => {
    setButtonLoadings(prevLoadings => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setButtonLoadings(prevLoadings => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 2000);
  }

  async function callGetUserInfoAPI(buttonAction: string) {
    if (!!validateUsername()) {
      try {
        await ApiService.get(`${base_url}${Urls.userInfo(userName, buttonAction === unlockAccountLabel ? 'UA' : '')}`)
          .then(
            (data) => {
              setErrorDescription("");
              if (
                !!verifyErrorAndShowMessage(data, apigetUserDetailsErrorMsg)
              ) {
                let userFactors: userInfoResponse = {
                  factor: [],
                  user_name: "",
                  recovery_question: "",
                  sessionToken: "",
                  config: { factors_ui_display: "", default_factor_to_show: "" },
                  show_change_password: false,
                  show_forgot_password: false,
                  show_unlock_account: false,
                  currentStep: ""
                };
                Object.keys(data).forEach((item) => {

                  if (item === "factors") {
                    Object.keys(data[item]).forEach((factorItem) => {
                      if (!!SSPRSupportedRecoveryFactors.some(supportedFactor =>
                        supportedFactor.factorType === data[item][factorItem]["factorType"] && supportedFactor.provider === data[item][factorItem]["factorProvider"] && supportedFactor.status === 'ACTIVE'
                      )) {
                        if (
                          data[item][factorItem].hasOwnProperty("hint")
                        ) {
                          userFactors.factor.push({
                            hint: data[item][factorItem]["hint"],
                            factorProvider:
                              data[item][factorItem]["factorProvider"],
                            factorType: data[item][factorItem]["factorType"],
                            factorStatus: data[item][factorItem]["factorStatus"],
                          });
                        } else {
                          userFactors.factor.push({
                            hint: "",
                            factorProvider:
                              data[item][factorItem]["factorProvider"],
                            factorType: data[item][factorItem]["factorType"],
                            factorStatus:
                              data[item][factorItem]["factorStatus"],
                          });

                        }
                      }
                    });
                  } else if (
                    item === userUsernameKey ||
                    item === userRecoveryQuestionKey ||
                    item === sessionTokenKey || item === 'config'
                  ) {
                    if (item === sessionTokenKey)
                      saveSessionToken(data[item]);
                    else
                      userFactors[item] = data[item];
                  }
                });
                // userFactors.factor.push({ factorProvider: "DUO", factorType: "web", factorStatus: "ACTIVE", question: "" })
                setUserState({
                  selectedOperation: buttonAction,
                  userInfo: userFactors,
                });
                // setCurrentPage1(factorsAuthorizeRoute);
                navigate(factorsAuthorizeRoute, { state: factorsAuthorizeRoute });
              } else {
                document.getElementById("user-name")?.focus();
                setErrorDescription(() => !!data.hasOwnProperty("errorCode") ? data.errorCode : data.hasOwnProperty("error") ? data.error : data.hasOwnProperty("message") ? data.message : "");
                setButtonLoadings([false, false, false])
              }

            },
            (error) => {
              openNotification("info", apigetUserDetailsErrorMsg);
            }
          )
          .catch((error) => {
            console.log(error);
            setButtonLoadings([false, false, false])
          }).finally(() => {
            setButtonLoadings([false, false, false])
          });
      } catch (error) {
        console.error("Error: ", error);
        setSelectedAction("");
        openNotification("info", apigetUserDetailsErrorMsg);
      }
    } else {
      setSelectedAction("");
      setButtonLoadings([false, false, false])
      openNotification("error", invalidUsernameMsg);
    }
  }

  return (
    <Skeleton loading={loading}><div>
      <div className="recovery-homepage-container" >
        {!!brandingDetails?.logo ? <div className={"recovery-homepage-logo recovery-homepage-div"} style={{
          height: "100px",
          margin: "0%",
          display: "flex",
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center"
        }}>
          <img alt="Branding" src={`${brandingDetails?.logo}`} style={{
            maxWidth: "90%",
            height: "80%",
          }} />
        </div> : ""
        }
        <div className={"factor-homepage-div"}>
          <Divider style={{ border: "1px", borderColor: "#d8d8d8" }}>
            {" "}
            <div
              className={
                "recovery-homepage-beacon-container factor-authorize-div"
              }
            >
              <div className={"recovery-homepage-beacon-logo"}>
                <img
                  className={"recovery-homepage-beacon-image"}
                  alt="Branding"
                  src={imageBeaconSrc}
                ></img>{" "}
              </div>
            </div>
          </Divider>
        </div>

        <div className={"recovery-homepage-div recovery-homepage-branding"}>
          <h2>{brandingDetails?.branding_title}</h2>
        </div>
        {!!errorDescription ? <div>
          <Alert type="error" showIcon
            description={errorDescription} ></Alert>
        </div> : ""}
        <div className={"recovery-homepage-fields-container"}>
          <div className={"recovery-homepage-fields"}>
            <div className={"recovery-homepage-username recovery-homepage-div"}>
              <Input
                placeholder="Enter username"
                id="user-name"
                autoFocus={true}
                size="large"
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
              &nbsp;
              <br />
            </div>
            {!!brandingDetails?.operations?.show_forgot_password ? <div className="recovery-homepage-button recovery-homepage-div">
              <Button
                type="primary"
                loading={buttonLoadings[0]}
                size="large"
                onClick={() => {
                  callGetUserInfoAPI(forgotPasswordLabel);
                  setSelectedAction(forgotPasswordLabel);
                  setButtonLoading(0)
                }}
              >
                {forgotPasswordLabel}
              </Button>
            </div> : ""}
            {!!brandingDetails?.operations?.show_change_password ? <div className="recovery-homepage-button recovery-homepage-div">
              <Button
                className={""}
                type="primary"
                size="large"
                loading={buttonLoadings[1]}
                onClick={() => {
                  callGetUserInfoAPI(changePasswordLabel);
                  setSelectedAction(changePasswordLabel);
                  setButtonLoading(1)

                }}
              >
                {changePasswordLabel}
              </Button>
            </div> : ""}
            {!!brandingDetails?.operations?.show_unlock_account ? <div className="recovery-homepage-button recovery-homepage-div">
              <Button
                className={""}
                type="primary"
                size="large"
                loading={buttonLoadings[2]}
                onClick={() => {
                  callGetUserInfoAPI(unlockAccountLabel);
                  setSelectedAction(unlockAccountLabel);
                  setButtonLoading(2);
                }}
              >
                {unlockAccountLabel}
              </Button>
            </div> : ""}
          </div>

          {!!brandingDetails?.instructions ? <div>
            <div style={{
              display: "flex",
              color: "grey"
            }}>
              <div>Help/Instructions:</div>
            </div>
            <div style={{
              display: "grid",
              color: "grey"
            }}>
              <div><RichTextViewer content={content} /></div>
            </div>
          </div> : ""}

        </div>

      </div>

    </div >
    </Skeleton>
  );
}
