import { Alert, Button, Divider, List, Select, Skeleton } from 'antd';
import Password from 'antd/lib/input/Password';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import QRCode from "react-qr-code";

import ApiService from '../../Api.service';
import Urls, {
    accountUnlockSuccessMsg,
    answerEmptyMsg,
    answerVerifySuccesMsg,
    apiforgotPasswordErrorMsg, apiPollForSuccessErrorMsg,
    APITimeoutErrorMsg,
    apiUnlockAccountErrorMsg,
    apiValidateChallengeErrorMsg, base_url, callFactorType, codeSentSuccessMsg, confirmationRoute, CREDENTIFactorProvider, DUOFactorProvider, DUOFactorType, DUOPushFactorProvider, DUOPushFactorType, DUOSMSFactorType, DUOTOTPFactorProvider, DUOTOTPFactorType, emailFactorType, FACE_AUTHFactoryType, forgotPasswordLabel, googleTOTPFactorType, HOTPFactorType, oktaPushFactorProvider, oktaPushFactorType,
    oktaTOTPFactorProvider, oktaTOTPFactorType, passcodeEmptyMsg, passcodeVerifySuccesMsg, pushApprovedMsg, pushSentSuccessMsg, pushTimeoutMsg, questionFactorType, recoveryHomePageRoute,
    recoveryOptionRoute, recoveryQuestionRoute, RSATokenFactorProvider, RSATokenFactorType, smsFactorType, unlockAccountLabel, voiceFactorType, yubicoTOTPHardwareProvider, yubicoTOTPHardwareType
} from '../../ApiUtils';
import UserContext from '../../features/userContext';
import { clearSessionStorage, componentMountCheck, getFactorImage, getSessionToken, saveSessionToken, setPushSessionToken, verifyErrorAndShowMessage } from '../../helpers/helper.service';
import {
    Branding,
    DUOFactorList,
    forgotPasswordPayload, SSPRSupportedRecoveryFactors, unlockAccountPayload, unlockAccountStepTwoPayload, UserAuthorizeFactors, userInfoResponse,
    validateChallengePayload, TecproofVerificationResult, TecproofVerification
} from '../../interfaces/api.interface';
import ssprService from '../../services/sspr.service';
import { openNotification } from '../Common/Notification';
import RichTextViewer from '../Common/RichTextViewer';
import './FactorAuthorize.css';
import useInterval from '../Common/useInterval';


export default function FactorAuthorize() {
    const focusInputField = () => {
        document.getElementById("codeTextField")?.focus();
    }
    const [selectedListItem, setSelectedListItem] = useState(null);
    const handleItemClick = (item: any) => {
        setSelectedListItem(item);
    }

    const selectedOperation = useParams().operation;
    const [factorListVisible, setFactorListVisible] = useState(true);
    const [timeOutId, setTimeOutId] = useState();
    const location = useLocation();
    const [errorDescription, setErrorDescription] = useState("");
    const [userState, setUserState] = useContext(UserContext);
    const [pushExpiryTime, setPushExpiryTime] = useState(new Date());
    const [selectedFactor, setSelectedFactor] = useState<UserAuthorizeFactors>();
    const [buttonLoadings, setButtonLoadings] = useState<boolean[]>([false, false, false]);
    const [duoButtonLoadings, setDUOButtonLoadings] = useState<boolean[]>([false, false, false, false]);
    const [duoPasscodeSectionVisi, setDuoPasscodeSectionVisi] = useState(false);
    const [duoSectionVisi, setDuoSectionVisi] = useState(false);
    const [challengeNumber, setChallengeNumber] = useState('');
    const [qrCodeValue, setQrCodeValue] = useState("");
    const [retryTecproof, setRetryTecproof] = useState(false);
    // const [qrCancel, setQrCancel] = useState(false);
    const [countDown, setCountDown] = useState(60); //60 seconds
    const setButtonLoading = (index: number) => {
        setButtonLoadings(prevLoadings => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });
    };

    const stopAllButtonLoadings = () => {
        setButtonLoadings(prevLoadings => {
            const newLoadings = [...prevLoadings];
            newLoadings[0] = false;
            newLoadings[1] = false;
            newLoadings[2] = false;
            return newLoadings;
        });
    }


    const [sendCodeButtonLabel, setSendCodeButtonLabel] = useState('Send Code');
    useMemo(() => {
        setErrorDescription("");
        setSendCodeButtonLabel("Send Code");
    }, [selectedFactor?.factorType]);
    const [sendPushButtonLabel, setSendPushButtonLabel] = useState("Send Push");
    useMemo(() => {
        setSendPushButtonLabel("Send push");
        setErrorDescription("");
    }, [selectedFactor?.factorType])

    const [defaultFactorToShow, setDefaultFactorToShow] = useState('')
    const [brandingDetails, setBrandingDetails] = useState<Branding>()
    const [authorizedFactors, setAuthorizedFactors] = useState<UserAuthorizeFactors[]>();
    const [verificationCode, setVerificationCode] = useState("");
    let navigate = useNavigate();
    const [userInfo, setUserInfo] = useState<userInfoResponse>();
    useEffect(() => {
        setChallengeNumber('');
        componentMountCheck(location.state as string);
        let temp = SSPRSupportedRecoveryFactors.filter(item => item.key === userState.userInfo.config.default_factor_to_show);
        setAuthorizedFactors(userState.userInfo.factor);
        setUserInfo(userState.userInfo);
        setBrandingDetails(ssprService.getBrandingDetails());
        if (!!ssprService.getBrandingDetails() && !!ssprService.getBrandingDetails().background_image)
            document.body.style.backgroundImage = `url(${ssprService.getBrandingDetails().background_image}?v=1)`;
        if (!!temp && temp.length > 0) {
            setDefaultFactorToShow(temp[0].factorType + '-' + temp[0].provider);
            const factorType = temp[0].factorType;
            const factorProvider = temp[0].provider;
            let defaultFactorExists = userState.userInfo.factor.filter(element => {
                return element.factorType === factorType && element.factorProvider === factorProvider
            });
            if (!!defaultFactorExists && defaultFactorExists.length > 0) {
                setImageBeaconSrc(factorType === HOTPFactorType ? "../../hotpFactor_70x70.png" : factorType === DUOFactorType ? "../../duo_70x70.e2baf65d97a5d6f2c083e14d4717ccf4.png" : (factorType === oktaPushFactorType || factorType === oktaTOTPFactorType) && factorProvider === oktaPushFactorProvider ? '../../oktaVerify_70x70.00aa672ea67517ca19e2b110d650e03f.png' : factorType === smsFactorType ? '../../sms_70x70.30e2a05096c1192ff1f96cf11acfdf17.png' : factorType === googleTOTPFactorType ? "../../googleAuth_38x38.750e8cb4f65b1d8f3a844baa0ac99454.png" : factorType === callFactorType || factorType === voiceFactorType ? "../../voicecall_70x70.b651a1f230ee1aea148c87773d533faf.png" : factorType === emailFactorType ? "../../email_38x38.b7ac950da2ba64f5886b21c9f97c7cc8.png" : factorType === yubicoTOTPHardwareType && factorProvider === yubicoTOTPHardwareProvider ? "../../yubico@2x.png" : factorProvider === RSATokenFactorProvider ? "../../rsa_70x70.e01fbd6241936eeb9cdf15c72cde49e9.png" : '../../question_38x38.8453db9ea592a3015add7fb268491a3b.png');
                if (temp[0].factorType + '-' + temp[0].provider && selectedFactor?.factorType + '-' + selectedFactor?.factorProvider && !!userState.userInfo.factor) {
                    for (const object of userState.userInfo.factor) {
                        if (object.factorType + '-' + object.factorProvider === (temp[0].factorType + '-' + temp[0].provider)) {
                            setSelectedFactor(object);
                            sessionStorage.setItem("selectedFactor", object.factorType + ":" + object.factorProvider);
                            focusInputField();
                            break;
                        }
                    }

                }

            } else {

                setDefaultFactorToShow("");
            }

        }
        if (userState.userInfo.currentStep === 'ADDITIONAL_MFA') {

            if (userState.userInfo.factor.length === 1 && userState.userInfo.factor[0].factorType === questionFactorType) {
                focusInputField();
                setFactorListVisible(false);
                setSelectedFactor(userState.userInfo.factor[0]);
                sessionStorage.setItem("selectedFactor", userState.userInfo.factor[0].factorType + ":" + userState.userInfo.factor[0].factorProvider);
            }

        }
        setLoading(false);
    }, [])
    const content = {
        ops: [
            { insert: brandingDetails?.instructions }
        ],
    };
    function handleAdditionalVerification(factors: UserAuthorizeFactors[]) {
        if (factors.length === 1 && factors[0].factorType === questionFactorType) {
            setFactorListVisible(false);
            setSelectedFactor({
                factorProvider: factors[0].factorProvider,
                factorType: factors[0].factorType,
                hint: factors[0].hint,
                factorStatus: factors[0].factorStatus
            });
            focusInputField();
            sessionStorage.setItem("selectedFactor", factors[0].factorType + ":" + factors[0].factorProvider);
            return
        }
        for (var i = 0; i < factors.length; i++) {
            if (factors[i].factorType === questionFactorType) {
                setAuthorizedFactors([factors[i]]);
                focusInputField();
                setFactorListVisible(false);
                setSelectedFactor({
                    factorProvider: factors[i].factorProvider,
                    factorType: factors[i].factorType,
                    hint: factors[i].hint,
                    factorStatus: factors[i].factorStatus
                });
                sessionStorage.setItem("selectedFactor", factors[i].factorType + ":" + factors[i].factorProvider);
                return;
            }
        }
        setAuthorizedFactors(factors);
    }

    async function handleGenerateTecproof() {
        setQrCodeValue("");
        // setQrCancel(false);
        setErrorDescription("");
        setButtonLoading(0);
        let body: TecproofVerification = { "sessionToken": getSessionToken(), email: (userState.userInfo as any).email ?? "", factor_type: selectedFactor?.factorType ?? "", factor_provider: selectedFactor?.factorProvider ?? "", actionType: userState.selectedOperation === forgotPasswordLabel ? "forgotPassword" : userState.selectedOperation == unlockAccountLabel ? "unlockAccount" : "changePassword" }
        await ApiService.post(`${base_url}/api/v1/recovery/tecproof/generate`, body).then(response => {
            if (!!response.access_token) {
                if (!!response.hasOwnProperty("sessionToken")) saveSessionToken(response.sessionToken);
                setQrCodeValue(response["redirect_url"]);
            } else {
                setRetryTecproof(true);
                setErrorDescription("Error in starting a new Tecproof facial authentication session");
                stopAllButtonLoadings();
            }
        }).catch(errror => {
            stopAllButtonLoadings();
            setErrorDescription("Error in starting a new Tecproof facial authentication session");
            setRetryTecproof(true);
        }).finally(() => { stopAllButtonLoadings(); });

    }
    async function handleVerifyTecproof() {
        setErrorDescription("");
        // setButtonLoading(0);
        // let tempRetry = retryTecproof;
        // setRetryTecproof(false);
        // return navigate(recoveryOptionRoute, { state: recoveryOptionRoute });
        try {
            let payload: TecproofVerificationResult = { sessionToken: getSessionToken(), email: (userState.userInfo as any).email ?? "", factor_type: selectedFactor?.factorType ?? "", factor_provider: selectedFactor?.factorProvider ?? "", actionType: userState.selectedOperation === forgotPasswordLabel ? "forgotPassword" : userState.selectedOperation == unlockAccountLabel ? "unlockAccount" : "changePassword" }
            let data = await ApiService.post(`${base_url}/api/v1/recovery/tecproof/verify`, payload)

            if (!data.inProgress) {
                if (data.success) {
                    if (data.sessionToken) saveSessionToken(data.sessionToken ?? "");
                    if (userState.selectedOperation == unlockAccountLabel) {
                        setQrCodeValue("");
                        await handleSendCodeClick(selectedFactor?.factorType ?? FACE_AUTHFactoryType);
                    } else {
                        return navigate(recoveryOptionRoute, { state: recoveryOptionRoute });
                    }
                } else {
                    // setQrCancel(true);
                    setRetryTecproof(true);
                    setErrorDescription("There seems to be some error. Please contact the admin.");
                }
            }
        } catch (error) {
            console.log(error)
            // setQrCancel(true);
            setErrorDescription("There was an unknown error in Face Auth verification. Retry or choose a different factor. " + error)
        }
        // setRetryTecproof(tempRetry);
        // stopAllButtonLoadings();
    }
    useInterval(() => {
        handleVerifyTecproof();
    }, selectedFactor?.factorProvider == CREDENTIFactorProvider && selectedFactor?.factorType == FACE_AUTHFactoryType
        //  && !qrCancel
        && !!qrCodeValue
        //  && countDown<25  
        ? 10000 : null)
    useInterval(() => {
        if (countDown == 0) {
            setRetryTecproof(true);
        } else {
            setCountDown(countDown - 1);
        }
    }, selectedFactor?.factorProvider == CREDENTIFactorProvider && selectedFactor?.factorType == FACE_AUTHFactoryType && !retryTecproof && countDown > -1 ? 1000 : null);
    useEffect(() => {
        if (selectedFactor?.factorProvider == CREDENTIFactorProvider && selectedFactor?.factorType == FACE_AUTHFactoryType && !qrCodeValue) {
            handleGenerateTecproof();
        }
    }, [selectedFactor])
    async function handleSendCodeClick(action: string, duoFactorType: string = "") {
        if (selectedFactor?.factorType === questionFactorType && !verificationCode) {
            openNotification("info", answerEmptyMsg);
            stopAllButtonLoadings();
            return
        }
        if(((selectedFactor?.factorType === HOTPFactorType) || (selectedFactor?.factorProvider === DUOTOTPFactorProvider && selectedFactor.factorType === DUOFactorType) || (selectedFactor?.factorProvider === RSATokenFactorProvider && selectedFactor?.factorType === RSATokenFactorType) || selectedFactor?.factorType === googleTOTPFactorType || selectedFactor?.factorType === oktaTOTPFactorType || (selectedFactor?.factorType === yubicoTOTPHardwareType && selectedFactor?.factorProvider === yubicoTOTPHardwareProvider)) && !verificationCode ){
            openNotification("info", passcodeEmptyMsg);
            stopAllButtonLoadings();
            return
        }
        if (userState.selectedOperation !== unlockAccountLabel) {
            let inputData: forgotPasswordPayload = {
                actionType: userState.selectedOperation === forgotPasswordLabel ? "forgotPassword" : "changePassword",
                answer: selectedFactor?.factorType === questionFactorType ? verificationCode : "",
                factor_type: !!duoFactorType ? duoFactorType : !!selectedFactor?.factorType ? selectedFactor?.factorType : "",
                one_time_code: (selectedFactor?.factorType === HOTPFactorType) || (selectedFactor?.factorProvider === DUOTOTPFactorProvider && selectedFactor.factorType === DUOFactorType) || (selectedFactor?.factorProvider === RSATokenFactorProvider && selectedFactor?.factorType === RSATokenFactorType) || selectedFactor?.factorType === googleTOTPFactorType || selectedFactor?.factorType === oktaTOTPFactorType || (selectedFactor?.factorType === yubicoTOTPHardwareType && selectedFactor?.factorProvider === yubicoTOTPHardwareProvider) ? verificationCode : "",
                factor_provider: !!duoFactorType ? 'DUO' : !!selectedFactor?.factorProvider ? selectedFactor?.factorProvider : "",
                username: !!userInfo?.user_name ? userInfo.user_name : "",
                sessionToken: getSessionToken(),
            }
            await ApiService.post(`${base_url}${Urls.forgotPassword}`, inputData).then(response => {
                stopAllButtonLoadings();
                setErrorDescription("");
                setDUOButtonLoadings([false, false, false, false]);
                if (!!verifyErrorAndShowMessage(response, apiforgotPasswordErrorMsg)) {
                    if (response.hasOwnProperty("factorResult")) {
                        if (response.factorResult === "ANSWER_RECOVERY_QUESTION") {
                            setChallengeNumber('');
                            setVerificationCode('');
                            setSelectedFactor({ factorProvider: "", factorStatus: "", factorType: "", hint: "" });
                            if (!!response.hasOwnProperty("sessionToken"))
                                saveSessionToken(response.sessionToken);
                            setUserState((prevState) => ({
                                userInfo: { config: prevState.userInfo.config, currentStep: "ANSWER_RECOVERY_QUESTION", factor: response.factors, recovery_question: response.recovery_question, sessionToken: userState.userInfo.sessionToken, show_change_password: userState.userInfo.show_change_password, show_forgot_password: userState.userInfo.show_forgot_password, show_unlock_account: userState.userInfo.show_unlock_account, user_name: userState.userInfo.user_name },
                                selectedOperation: userState.selectedOperation
                            }));
                            navigate(recoveryQuestionRoute, { state: recoveryQuestionRoute });
                        } else if (response.factorResult === "ADDITIONAL_MFA") {
                            setChallengeNumber('');
                            setVerificationCode('');
                            setSelectedFactor({ factorProvider: "", factorStatus: "", factorType: "", hint: "" });
                            if (!!response.hasOwnProperty("sessionToken"))
                                saveSessionToken(response.sessionToken);
                            setUserState((prevState) => ({
                                userInfo: { config: prevState.userInfo.config, currentStep: "ADDITIONAL_MFA", factor: response.factors, recovery_question: "", sessionToken: userState.userInfo.sessionToken, show_change_password: userState.userInfo.show_change_password, show_forgot_password: userState.userInfo.show_forgot_password, show_unlock_account: userState.userInfo.show_unlock_account, user_name: userState.userInfo.user_name },
                                selectedOperation: userState.selectedOperation
                            }));
                            handleAdditionalVerification(response.factors)
                        } else if (response.factorResult === "PASSWORD_RESET") {
                            setChallengeNumber('');
                            setVerificationCode('');
                            if (!!response.hasOwnProperty("sessionToken"))
                                saveSessionToken(response.sessionToken)
                            navigate(recoveryOptionRoute, { state: recoveryOptionRoute });
                        } else if (response.factorResult === "CHALLENGE" && (action === smsFactorType || action === callFactorType || action === voiceFactorType || action === emailFactorType)) {
                            setChallengeNumber('');
                            saveSessionToken(response.sessionToken);
                            setSendCodeButtonLabel("Sent");
                            focusInputField();
                            openNotification("info", codeSentSuccessMsg)
                            setErrorDescription("");
                            setTimeout(() => {
                                var factor_session = sessionStorage.getItem("selectedFactor");
                                if (factor_session && (factor_session?.indexOf(smsFactorType) >= 0 || factor_session?.indexOf(callFactorType) >= 0 || factor_session?.indexOf(voiceFactorType) >= 0 || factor_session?.indexOf(emailFactorType) >= 0)) {
                                    setErrorDescription("Haven't received an " + action + "? To try again, click Re-send code.")
                                    setSendCodeButtonLabel("Re-send code")

                                }
                            }, 30000);
                        } else if (action === oktaPushFactorType && response.factorResult === "WAITING") {
                            setChallengeNumber(response.hint);
                            setPushSessionToken(response.sessionToken);
                            saveSessionToken(response.sessionToken);
                            setSendPushButtonLabel("Push sent!")
                            setPushExpiryTime(new Date(response.expiresAt));
                            openNotification("info", pushSentSuccessMsg);
                            setTimeout(() => {
                                var factor_session = sessionStorage.getItem("selectedFactor")
                                if (selectedFactor?.factorProvider === oktaPushFactorProvider && !!factor_session && factor_session.indexOf("push") >= 0) {
                                    setErrorDescription("Haven't received a push notification yet? Try opening the Okta Verify App on your phone.")
                                    setSendPushButtonLabel("Re-send Push")
                                }
                            }, 30000)
                            handlePollAPI();
                        } else if ((action === HOTPFactorType || action === RSATokenFactorType || action === yubicoTOTPHardwareType || action === oktaTOTPFactorType || action === questionFactorType) && response.factorResult.toUpperCase() === "SUCCESS") {
                            setChallengeNumber('');
                            saveSessionToken(response.sessionToken);
                            openNotification("success", passcodeVerifySuccesMsg);
                            if (!!userInfo?.recovery_question) {
                                navigate(recoveryQuestionRoute, { state: recoveryQuestionRoute });
                            } else {
                                navigate(recoveryOptionRoute, { state: recoveryOptionRoute });
                            }
                        } else if (response.factorResult.toUpperCase() === "PASSCODE") {
                            setChallengeNumber('');
                            if (response.hasOwnProperty("message")) {
                                openNotification("error", response.message);
                            } else
                                openNotification("error", response.factorResult);
                        }
                        else {
                            setChallengeNumber('');
                            focusInputField();
                            saveSessionToken(response.sessionToken);
                            openNotification("info", response.factorResult);
                        }
                    } else if (response.hasOwnProperty("nextStep")) {

                        saveSessionToken(response.sessionToken);
                        openNotification("success", answerVerifySuccesMsg);
                        if (!!userInfo?.recovery_question) {
                            // setCurrentPage1(recoveryQuestionRoute);
                            navigate(recoveryQuestionRoute, { state: recoveryQuestionRoute });
                        }
                        else {
                            navigate(recoveryOptionRoute, { state: recoveryOptionRoute });
                            // setCurrentPage1(recoveryOptionRoute);
                        }
                    } else {
                        stopAllButtonLoadings();
                        openNotification("info", apiforgotPasswordErrorMsg);
                    }
                } else {
                    if (response.hasOwnProperty("errorSummary")) {
                        setErrorDescription(response.errorSummary)
                    }
                    focusInputField();
                }
            }).catch(error => {
                console.error('Error: ', error);
                stopAllButtonLoadings();
                setErrorDescription(APITimeoutErrorMsg);
            }).finally(() => {
                stopAllButtonLoadings();
            })
        } else {
            let payload: unlockAccountPayload = {
                factor_type: !!duoFactorType ? duoFactorType : !!selectedFactor?.factorType ? selectedFactor?.factorType : "",
                answer: selectedFactor?.factorType === questionFactorType ? verificationCode : "",
                one_time_code: (selectedFactor?.factorType === HOTPFactorType) || (selectedFactor?.factorProvider === yubicoTOTPHardwareProvider || selectedFactor?.factorType === yubicoTOTPHardwareType) || selectedFactor?.factorType === googleTOTPFactorType || selectedFactor?.factorType === oktaTOTPFactorType ? verificationCode : "",
                factor_provider: !!duoFactorType ? 'DUO' : !!selectedFactor?.factorProvider ? selectedFactor?.factorProvider : "",
                username: userState.userInfo.user_name,
                sessionToken: getSessionToken(),
            }
            await ApiService.post(`${base_url}${Urls.unlockAccount}`, payload).then(response => {
                stopAllButtonLoadings();
                setDUOButtonLoadings([false, false, false, false]);
                if (!!verifyErrorAndShowMessage(response, apiUnlockAccountErrorMsg)) {
                    if (!!response.hasOwnProperty("next step") && response['next step'] === "validate two step factor") {
                        setChallengeNumber('');
                        saveSessionToken(response.sessionToken);
                        setSendCodeButtonLabel("Sent");
                        setErrorDescription("");
                        setTimeout(() => {
                            var factor_session = sessionStorage.getItem("selectedFactor");
                            if (factor_session && (factor_session?.indexOf(smsFactorType) >= 0 || factor_session?.indexOf(callFactorType) >= 0 || factor_session?.indexOf(voiceFactorType) >= 0 || factor_session?.indexOf(emailFactorType) >= 0)) {
                                setErrorDescription("Haven't received an " + action + "? To try again, click Re-send code.")
                                setSendCodeButtonLabel("Re-send code")
                            }
                        }, 30000);
                        openNotification("success", selectedFactor?.factorType === "sms" ||
                            selectedFactor?.factorType === emailFactorType
                            || selectedFactor?.factorType === callFactorType || selectedFactor?.factorType === voiceFactorType ? codeSentSuccessMsg : "");
                        focusInputField();
                    } if (response.factorResult === "CHALLENGE" && (action === smsFactorType || action === callFactorType || action === voiceFactorType || action === emailFactorType)) {
                        setChallengeNumber('');
                        saveSessionToken(response.sessionToken);
                        setSendCodeButtonLabel("Sent");
                        setErrorDescription("");
                        setTimeout(() => {
                            var factor_session = sessionStorage.getItem("selectedFactor");
                            if (factor_session && (factor_session?.indexOf(smsFactorType) >= 0 || factor_session?.indexOf(callFactorType) >= 0 || factor_session?.indexOf(voiceFactorType) >= 0 || factor_session?.indexOf(emailFactorType) >= 0)) {
                                setErrorDescription("Haven't received an " + action + "? To try again, click Re-send code.")
                                setSendCodeButtonLabel("Re-send code");
                            }
                        }, 30000)
                        openNotification("info", codeSentSuccessMsg);
                        focusInputField();
                    } else if (action === oktaPushFactorType && response.factorResult === "WAITING") {
                        setChallengeNumber(response.hint);
                        setPushSessionToken(response.sessionToken);
                        setSendPushButtonLabel("Push sent!")
                        setPushExpiryTime(new Date(response.expiresAt));
                        openNotification("info", pushSentSuccessMsg);
                        setTimeout(() => {
                            var factor_session = sessionStorage.getItem("selectedFactor")
                            if (selectedFactor?.factorProvider === oktaPushFactorProvider && !!factor_session && factor_session.indexOf("push") >= 0) {
                                setErrorDescription("Haven't received a push notification yet? Try opening the Okta Verify App on your phone.")
                                setSendPushButtonLabel("Re-send Push")
                            }
                        }, 30000)
                        handlePollAPI();
                    }
                    else if (response.hasOwnProperty("recoveryType") && response.recoveryType === "UNLOCK") {
                        setChallengeNumber('');
                        saveSessionToken(response.sessionToken);
                        openNotification("success", selectedFactor?.factorType === questionFactorType ? answerVerifySuccesMsg : passcodeVerifySuccesMsg);
                        if (response.hasOwnProperty("Next")) {
                            userState.userInfo.recovery_question = response.Next;
                            setUserState(userState);
                            navigate(recoveryQuestionRoute, { state: recoveryQuestionRoute });
                        } else {
                            navigate(confirmationRoute, { state: confirmationRoute })
                        }
                    } else if (response.factorResult === "ADDITIONAL_MFA") {
                        setChallengeNumber('');
                        setVerificationCode('');
                        setSelectedFactor({ factorProvider: "", factorStatus: "", factorType: "", hint: "" });
                        if (!!response.hasOwnProperty("sessionToken"))
                            saveSessionToken(response.sessionToken);
                        setUserState((prevState) => ({
                            userInfo: { config: prevState.userInfo.config, currentStep: "ADDITIONAL_MFA", factor: response.factors, recovery_question: "", sessionToken: userState.userInfo.sessionToken, show_change_password: userState.userInfo.show_change_password, show_forgot_password: userState.userInfo.show_forgot_password, show_unlock_account: userState.userInfo.show_unlock_account, user_name: userState.userInfo.user_name },
                            selectedOperation: userState.selectedOperation
                        }));
                        // setLoading(true);
                        handleAdditionalVerification(response.factors)
                    } else if (response.factorResult === "PASSWORD_RESET") {
                        setChallengeNumber('');
                        setVerificationCode('');
                        if (!!response.hasOwnProperty("sessionToken"))
                            saveSessionToken(response.sessionToken)
                        navigate(recoveryOptionRoute, { state: recoveryOptionRoute });
                    } else if (response.factorResult.toUpperCase() === "PASSCODE") {
                        setChallengeNumber('');
                        if (response.hasOwnProperty("message")) {
                            openNotification("error", response.message);
                        } else
                            openNotification("error", response.factorResult);
                    } else if (response.hasOwnProperty("factorResult") && !!response.factorResult) {
                        setChallengeNumber('');
                        openNotification("info", response.factorResult);
                        focusInputField();
                    }
                } else {
                    if (response.hasOwnProperty("errorSummary")) {
                        setErrorDescription(response.errorSummary)
                    }
                    focusInputField();
                }
            }).catch(error => {
                stopAllButtonLoadings();
                setErrorDescription(APITimeoutErrorMsg);
            }).finally(() => {
                stopAllButtonLoadings();
            });
        }

    }

    const handlePollAPI = () => {
        let pollResponse: any;
        setTimeout(() => ApiService.get(`${base_url}${Urls.pollForPushSuccess(!!userInfo?.user_name ? userInfo.user_name : "")}`).then((response) => {
            if ((sessionStorage.getItem('selectedFactor') === 'push:OKTA') && !!response && !!verifyErrorAndShowMessage(response, apiPollForSuccessErrorMsg)) {
                if (response.factorResult === "ANSWER_RECOVERY_QUESTION") {
                    setChallengeNumber('');
                    setVerificationCode('');
                    sessionStorage.setItem("selectedFactor", "");
                    setSelectedFactor({ factorProvider: "", factorStatus: "", factorType: "", hint: "" });
                    if (!!response.hasOwnProperty("sessionToken"))
                        saveSessionToken(response.sessionToken);
                    setUserState((prevState) => ({
                        userInfo: { config: prevState.userInfo.config, currentStep: response.factorResult, factor: response.factors, recovery_question: response.recovery_question, sessionToken: userState.userInfo.sessionToken, show_change_password: userState.userInfo.show_change_password, show_forgot_password: userState.userInfo.show_forgot_password, show_unlock_account: userState.userInfo.show_unlock_account, user_name: userState.userInfo.user_name },
                        selectedOperation: userState.selectedOperation
                    }));
                    navigate(recoveryQuestionRoute, { state: recoveryQuestionRoute });
                } else if (response.factorResult === "ADDITIONAL_MFA") {
                    setChallengeNumber('');
                    setVerificationCode('');
                    sessionStorage.setItem("selectedFactor", "");
                    setSelectedFactor({ factorProvider: "", factorStatus: "", factorType: "", hint: "" });
                    if (!!response.hasOwnProperty("sessionToken"))
                        saveSessionToken(response.sessionToken);
                    setUserState((prevState) => ({
                        userInfo: { config: prevState.userInfo.config, currentStep: "ADDITIONAL_MFA", factor: response.factors, recovery_question: "", sessionToken: userState.userInfo.sessionToken, show_change_password: userState.userInfo.show_change_password, show_forgot_password: userState.userInfo.show_forgot_password, show_unlock_account: userState.userInfo.show_unlock_account, user_name: userState.userInfo.user_name },
                        selectedOperation: userState.selectedOperation
                    }));
                    handleAdditionalVerification(response.factors);
                } else if (response.factorResult === "PASSWORD_RESET") {
                    setChallengeNumber('');
                    setVerificationCode('');
                    if (!!response.hasOwnProperty("sessionToken"))
                        saveSessionToken(response.sessionToken)
                    navigate(recoveryOptionRoute, { state: recoveryOptionRoute });
                } else if ((response.hasOwnProperty("factorResult") && response.factorResult.toUpperCase() === "SUCCESS") || (response.hasOwnProperty("status") && response.status.toUpperCase() === "SUCCESS")) {
                    setChallengeNumber('');
                    saveSessionToken(response.sessionToken);
                    openNotification("success", pushApprovedMsg);
                    if (userState.selectedOperation === 'Unlock Account') {
                        if (response.hasOwnProperty("Next")) {
                            userState.userInfo.recovery_question = response.Next;
                            setUserState(userState);
                            navigate(recoveryQuestionRoute, { state: recoveryQuestionRoute });
                        } else {
                            navigate(confirmationRoute, { state: confirmationRoute });
                            return
                        }
                    }
                    if (!!userInfo?.recovery_question) {
                        navigate(recoveryQuestionRoute, { state: recoveryQuestionRoute });
                    }
                    else {
                        navigate(recoveryOptionRoute, { state: recoveryOptionRoute });
                    }
                } else if (response.factorResult === "REJECTED") {
                    setChallengeNumber('');
                    openNotification("error", "Push Rejected");
                    clearSessionStorage();
                    navigate("/");
                } else if (response.factorResult === "TIMEOUT") {
                    setChallengeNumber('');
                    openNotification("info", pushTimeoutMsg);
                } else if (response.factorResult === 'WAITING') {
                    setPushSessionToken(response.sessionToken);
                    if (!!response.hasOwnProperty("expiresAt") && (new Date()).getTime() < (new Date(response.expiresAt).getTime()))
                        handlePollAPI();
                }
            } else {
                if (response.hasOwnProperty("errorSummary")) {
                    setErrorDescription(response.errorSummary)
                }
                focusInputField();
            }
        }).catch(error => {
            stopAllButtonLoadings();
            pollResponse = error;
            openNotification('error', apiPollForSuccessErrorMsg);
        }), 5000);
        return pollResponse;
    }
    async function handleVerifyClick() {
        sessionStorage.setItem("stop-poll", 'true');
        if (userState.selectedOperation !== unlockAccountLabel) {
            if (selectedFactor?.factorType === HOTPFactorType || (selectedFactor?.factorType === yubicoTOTPHardwareType && selectedFactor?.factorProvider === yubicoTOTPHardwareProvider) || selectedFactor?.factorType === googleTOTPFactorType || selectedFactor?.factorType === oktaTOTPFactorType || selectedFactor?.factorType === questionFactorType) {
                handleSendCodeClick(selectedFactor?.factorType);
            }
            else {
                if (!!verificationCode) {
                    let selected = sessionStorage.getItem("selectedFactor");
                    let selected_split: string[] = []
                    if (!!selected) {
                        selected_split = selected.split(":");
                    }
                    try {
                        let payload: validateChallengePayload = {
                            passCode: !!verificationCode ? verificationCode : "",
                            username: !!userInfo?.user_name ? userInfo.user_name : "",
                            sessionToken: getSessionToken(),
                            factor_provider: selected_split[1],
                            factor_type: selected_split[1] === "DUO" ? "sms" : selected_split[0]
                        }
                        console.log(payload)
                        await ApiService.post(`${base_url}${Urls.validateChallenge}`, payload).then((response) => {
                            stopAllButtonLoadings();
                            if (!!verifyErrorAndShowMessage(response, apiValidateChallengeErrorMsg)) {
                                if (response.hasOwnProperty("factorResult")) {
                                    if (response.factorResult === "ANSWER_RECOVERY_QUESTION") {
                                        openNotification("success", passcodeVerifySuccesMsg);
                                        if (!!response.hasOwnProperty("sessionToken"))
                                            saveSessionToken(response.sessionToken)
                                        setSelectedFactor({ factorProvider: "", factorStatus: "", factorType: "", hint: "" });
                                        if (!!response.hasOwnProperty("sessionToken"))
                                            saveSessionToken(response.sessionToken);
                                        setUserState((prevState) => ({
                                            userInfo: { config: prevState.userInfo.config, currentStep: "ANSWER_RECOVERY_QUESTION", factor: response.factors, recovery_question: response.recovery_question, sessionToken: userState.userInfo.sessionToken, show_change_password: userState.userInfo.show_change_password, show_forgot_password: userState.userInfo.show_forgot_password, show_unlock_account: userState.userInfo.show_unlock_account, user_name: userState.userInfo.user_name },
                                            selectedOperation: userState.selectedOperation
                                        }));
                                        navigate(recoveryQuestionRoute, { state: recoveryQuestionRoute });
                                    } else if (response.factorResult.toUpperCase() === "SUCCESS") {
                                        openNotification("success", passcodeVerifySuccesMsg);
                                        if (!!response.hasOwnProperty("sessionToken"))
                                            saveSessionToken(response.sessionToken)
                                        if (!!userInfo?.recovery_question) {
                                            navigate(recoveryQuestionRoute, { state: recoveryQuestionRoute });
                                        }
                                        else {
                                            navigate(recoveryOptionRoute, { state: recoveryOptionRoute });
                                        }
                                    } else if (response.factorResult === "ADDITIONAL_MFA") {
                                        openNotification("success", passcodeVerifySuccesMsg);
                                        sessionStorage.setItem("selectedFactor", "");
                                        // setLoading(true);
                                        setVerificationCode('');
                                        setSelectedFactor({ factorProvider: "", factorStatus: "", factorType: "", hint: "" });
                                        if (!!response.hasOwnProperty("sessionToken"))
                                            saveSessionToken(response.sessionToken);
                                        setUserState((prevState) => ({
                                            userInfo: { config: prevState.userInfo.config, currentStep: "ADDITIONAL_MFA", factor: response.factors, recovery_question: "", sessionToken: userState.userInfo.sessionToken, show_change_password: userState.userInfo.show_change_password, show_forgot_password: userState.userInfo.show_forgot_password, show_unlock_account: userState.userInfo.show_unlock_account, user_name: userState.userInfo.user_name },
                                            selectedOperation: userState.selectedOperation
                                        }));
                                        handleAdditionalVerification(response.factors);
                                        setLoading(false);
                                    } else if (response.factorResult === "PASSWORD_RESET") {
                                        openNotification("success", passcodeVerifySuccesMsg);
                                        setVerificationCode('');
                                        if (!!response.hasOwnProperty("sessionToken"))
                                            saveSessionToken(response.sessionToken)
                                        navigate(recoveryOptionRoute, { state: recoveryOptionRoute });
                                    } else if (response.factorResult === 'CHALLENGE') {
                                        if (!!response.hasOwnProperty("message")) {
                                            setErrorDescription(response.message);
                                            openNotification("error", response.message);
                                        }
                                    }
                                }
                            } else {
                                if (response.hasOwnProperty("errorSummary")) {
                                    setErrorDescription(response.errorSummary)
                                }
                                focusInputField();
                            }
                        }).catch(error => {
                            stopAllButtonLoadings();
                            focusInputField();
                            openNotification("info", apiValidateChallengeErrorMsg);
                        }).finally(() => {
                            stopAllButtonLoadings();
                        });
                    } catch (e) {
                        stopAllButtonLoadings();
                        openNotification("info", apiValidateChallengeErrorMsg);
                    }
                } else {
                    stopAllButtonLoadings();
                    openNotification("info", passcodeEmptyMsg)
                }
            }
        } else {
            if (selectedFactor?.factorType === HOTPFactorType || (selectedFactor?.factorType === yubicoTOTPHardwareType && selectedFactor?.factorProvider === yubicoTOTPHardwareProvider) || (selectedFactor?.factorType === oktaTOTPFactorType && selectedFactor?.factorProvider === oktaTOTPFactorProvider) || selectedFactor?.factorType === oktaTOTPFactorType || selectedFactor?.factorType === googleTOTPFactorType || selectedFactor?.factorType === questionFactorType) {
                handleSendCodeClick(selectedFactor.factorType);
            } else {
                if (!verificationCode) {
                    stopAllButtonLoadings();
                    openNotification("info", passcodeEmptyMsg);
                    return
                }
                let payload: unlockAccountStepTwoPayload = {
                    passCode: verificationCode,
                    sessionToken: getSessionToken(),
                    username: userState.userInfo.user_name,
                    factor_provider: selectedFactor?.factorProvider ? selectedFactor?.factorProvider : "",
                    factor_type: !!selectedFactor?.factorType ? selectedFactor?.factorType : "",

                }
                await ApiService.post(`${base_url}${Urls.unlockAccount_stepTwo}`, payload).then(response => {
                    stopAllButtonLoadings();
                    if (!!verifyErrorAndShowMessage(response, apiValidateChallengeErrorMsg)) {

                        if (response.hasOwnProperty("factorResult") && response.factorResult === "SUCCESS") {
                            if (response.hasOwnProperty("question") && !!response.question) {
                                openNotification("success", passcodeVerifySuccesMsg);
                                saveSessionToken(response.sessionToken);
                                userState.userInfo.recovery_question = response.question;
                                setUserState(userState);
                                // setCurrentPage1(recoveryQuestionRoute);
                                navigate(recoveryQuestionRoute, { state: recoveryQuestionRoute });
                            } else {
                                // setCurrentPage1(confirmationRoute);
                                navigate(confirmationRoute, { state: confirmationRoute });
                            }
                        } else if (response.hasOwnProperty("recoveryType") && response.recoveryType === "UNLOCK") {
                            setChallengeNumber('');
                            saveSessionToken(response.sessionToken);
                            openNotification("success", selectedFactor?.factorType === questionFactorType ? answerVerifySuccesMsg : passcodeVerifySuccesMsg);
                            if (response.hasOwnProperty("Next")) {
                                userState.userInfo.recovery_question = response.Next;
                                setUserState(userState);
                                navigate(recoveryQuestionRoute, { state: recoveryQuestionRoute });
                            } else {
                                navigate(confirmationRoute, { state: confirmationRoute })
                            }

                        } else if (response.factorResult === "ADDITIONAL_MFA") {
                            sessionStorage.setItem("selectedFactor", "");
                            setVerificationCode('');
                            setSelectedFactor({ factorProvider: "", factorStatus: "", factorType: "", hint: "" });
                            if (!!response.hasOwnProperty("sessionToken"))
                                saveSessionToken(response.sessionToken);
                            setUserState((prevState) => ({
                                userInfo: { config: prevState.userInfo.config, currentStep: "ADDITIONAL_MFA", factor: response.factors, recovery_question: "", sessionToken: userState.userInfo.sessionToken, show_change_password: userState.userInfo.show_change_password, show_forgot_password: userState.userInfo.show_forgot_password, show_unlock_account: userState.userInfo.show_unlock_account, user_name: userState.userInfo.user_name },
                                selectedOperation: userState.selectedOperation
                            }))
                            handleAdditionalVerification(response.factors);
                        } else if (response.factorResult === "PASSWORD_RESET") {
                            setVerificationCode('');
                            if (!!response.hasOwnProperty("sessionToken"))
                                saveSessionToken(response.sessionToken)
                            navigate(recoveryOptionRoute, { state: recoveryOptionRoute });
                        } else {
                            if (response.hasOwnProperty("FactorResult") && !!response.FactorResult) {
                                stopAllButtonLoadings();
                                openNotification("info", response.FactorResult);
                            }
                            else {
                                stopAllButtonLoadings();
                                openNotification("info", "An error occurred while processing request");
                            }
                        }
                    } else {
                        if (response.hasOwnProperty("errorSummary")) {
                            setErrorDescription(response.errorSummary);
                        }
                        focusInputField();
                    }

                }).catch(error => {
                    stopAllButtonLoadings();
                })
            }
        }
        setDUOButtonLoadings([false, false, false, false]);
    }
    const [imageBeaconSrc, setImageBeaconSrc] = useState("../../human.png");
    const [loading, setLoading] = useState<boolean>(true)
    return <Skeleton loading={loading}> <div className="recovery-homepage-container">
        {!!brandingDetails?.logo ?
            <div className={"factor-authorize-logo factor-authorize-div"} style={{
                height: "100px",
                display: "flex",
                flexGrow: 1,
                justifyContent: "center",
                alignItems: "center"
            }}>
                <img alt="Branding" src={`${brandingDetails?.logo}`} style={{
                    maxWidth: "90%",
                    height: "80%",
                }} />
            </div> : ""}
        <div className={"factor-authorize-div"}>
            <Divider style={{ border: "1px", borderColor: '#d8d8d8' }}> <div className={"factor-authorize-beacon-container factor-authorize-div"}>
                <div className={"factor-authorize-beacon-logo"}><img className={"factor-authorize-beacon-image"} alt="Branding" src={imageBeaconSrc} ></img> </div>
            </div></Divider>
        </div>
        <div className={"recovery-homepage-div recovery-homepage-branding"}>
            <h1>{userState.selectedOperation}</h1>
        </div>
        <div className={"factor-authorize-fields-container"}>
            <div>
                <h3>{!!userState.userInfo.currentStep && userState.userInfo.currentStep === 'ADDITIONAL_MFA' ? "Additional Verification" : ""}</h3>
            </div>
            <div className={"factor-authorize-fields"}>
                {!!factorListVisible ? <> {userState.userInfo.config.factors_ui_display === 'LIST' ? <List
                    bordered
                    size="small"
                    style={{
                        border: "1px solid #d8d8d8", overflow: "auto",
                        maxHeight: "350px"
                    }}
                    dataSource={authorizedFactors}
                    renderItem={(item) => (
                        <List.Item
                            style={{
                                cursor: "pointer",
                                backgroundColor: selectedListItem === item ? 'lightblue' : 'white'
                            }} onClick={() => {
                                handleItemClick(item);
                                sessionStorage.setItem("selectedFactor", item.factorType + ":" + item.factorProvider);
                                setSelectedFactor(item);
                                setVerificationCode('');
                                focusInputField();
                                setDuoSectionVisi(item.factorProvider === DUOFactorProvider ? true : false);
                                setImageBeaconSrc(item['factorType'] === HOTPFactorType ? "../../hotpFactor_38x38.png" : item['factorProvider'] === DUOFactorProvider ? "../../duo_70x70.e2baf65d97a5d6f2c083e14d4717ccf4.png" : (item['factorType'] === oktaPushFactorType || item['factorType'] === oktaTOTPFactorType) && item['factorProvider'] === oktaPushFactorProvider ? '../../oktaVerify_70x70.00aa672ea67517ca19e2b110d650e03f.png' : item['factorType'] === smsFactorType ? '../../sms_70x70.30e2a05096c1192ff1f96cf11acfdf17.png' : item['factorType'] === googleTOTPFactorType ? "../../googleAuth_38x38.750e8cb4f65b1d8f3a844baa0ac99454.png" : item["factorType"] === callFactorType || item["factorType"] === voiceFactorType ? "../../voicecall_70x70.b651a1f230ee1aea148c87773d533faf.png" : item['factorType'] === emailFactorType ? "../../email_38x38.b7ac950da2ba64f5886b21c9f97c7cc8.png" : item['factorType'] === yubicoTOTPHardwareType && item['factorProvider'] === yubicoTOTPHardwareProvider ? "../../yubico@2x.png" : item['factorProvider'] === RSATokenFactorProvider ? "../../rsa_70x70.e01fbd6241936eeb9cdf15c72cde49e9.png" : item['factorProvider'] === CREDENTIFactorProvider ? "../../credenti-favicon.png" : '../../question_38x38.8453db9ea592a3015add7fb268491a3b.png');
                            }}>
                            <img className={"factor-authorize-item-logo"}
                                alt={item['factorType']}
                                style={{
                                    width: "15%",
                                }}
                                src={item['factorType'] === HOTPFactorType ? "../../hotpFactor_38x38.png" : item['factorProvider'] === DUOFactorProvider ? "../../duo_38x38.9944c099fa2327e9e4da2b33c9516593.png" : (item['factorType'] === oktaPushFactorType || item['factorType'] === oktaTOTPFactorType) && item['factorProvider'] === oktaPushFactorProvider ? '../../oktaVerify_38x38.ec588b98b16b2ddd8a0256ffbc3bab88.png' : item['factorType'] === questionFactorType ? '../../question_38x38.8453db9ea592a3015add7fb268491a3b.png' : item['factorType'] === smsFactorType ? '../../sms_38x38.4661fd6ccaded3a68b1803ac32b26592.png' : item['factorType'] === emailFactorType ? "../../email_38x38.b7ac950da2ba64f5886b21c9f97c7cc8.png" : item['factorType'] === googleTOTPFactorType ? "../../googleAuth_38x38.750e8cb4f65b1d8f3a844baa0ac99454.png" : item['factorType'] === emailFactorType ? "../../email_38x38.b7ac950da2ba64f5886b21c9f97c7cc8.png" : item["factorType"] === callFactorType || item["factorType"] === voiceFactorType ? "../../voicecall_38x38.98ee4e6eac4e482dc8b2db9eadafb031.png" : item['factorProvider'] === yubicoTOTPHardwareProvider ? "../../yubico.png" : item['factorProvider'] === RSATokenFactorProvider ? "../../rsa_38x38.44b8b7f07f63b71ca53c6164d07d933d.png" : item['factorProvider'] === CREDENTIFactorProvider ? "../../credenti-favicon.png" : ""}
                            ></img> {item['factorType'] === HOTPFactorType ? "Feitian Hardware Token" : item['factorProvider'] === DUOFactorProvider ? "DUO Security" : item['factorType'] === oktaPushFactorType && item['factorProvider'] === oktaPushFactorProvider ? 'OKTA Verify With Push' : item['factorType'] === questionFactorType ? 'Security Question' : item['factorType'] === oktaTOTPFactorType && item['factorProvider'] === oktaTOTPFactorProvider ? 'OKTA Verify' : item['factorType'] === smsFactorType ? 'SMS Authentication' : item['factorType'] === googleTOTPFactorType ? "Google Authenticator" : item['factorType'] === emailFactorType ? "Email Authentication" : item["factorType"] === callFactorType || item["factorType"] === voiceFactorType ? "Voice Call Authentication" : item['factorProvider'] === yubicoTOTPHardwareProvider ? "Yubico Authentication" : item['factorProvider'] === RSATokenFactorProvider ? "RSA Authentication" : item["factorProvider"] === CREDENTIFactorProvider ? "Credenti Face Authentication" : ""}
                        </List.Item>
                    )}
                /> : <Select placeholder="Please select factor"
                    style={{ width: '100%' }}
                    size="large"
                    defaultValue={defaultFactorToShow}
                    onChange={(value) => {
                        const factorType = value.split('-')[0];
                        const factorProvider = value.split('-')[1];
                        sessionStorage.setItem("selectedFactor", factorType + ":" + factorProvider);
                        setDuoSectionVisi(factorProvider === DUOFactorProvider ? true : false);
                        setImageBeaconSrc(factorType === HOTPFactorType ? "../../hotpFactor_70x70.png" : factorProvider === DUOFactorProvider ? "duo_70x70.e2baf65d97a5d6f2c083e14d4717ccf4.png" : (factorType === oktaPushFactorType || factorType === oktaTOTPFactorType) && factorProvider === oktaPushFactorProvider ? '../../oktaVerify_70x70.00aa672ea67517ca19e2b110d650e03f.png' : factorType === smsFactorType ? '../../sms_70x70.30e2a05096c1192ff1f96cf11acfdf17.png' : factorType === googleTOTPFactorType ? "../../googleAuth_38x38.750e8cb4f65b1d8f3a844baa0ac99454.png" : factorType === callFactorType || factorType === voiceFactorType ? "../../voicecall_70x70.b651a1f230ee1aea148c87773d533faf.png" : factorType === emailFactorType ? "../../email_38x38.b7ac950da2ba64f5886b21c9f97c7cc8.png" : factorType === yubicoTOTPHardwareType && factorProvider === yubicoTOTPHardwareProvider ? "../../yubico@2x.png" : factorProvider === RSATokenFactorProvider ? "../../rsa_70x70.e01fbd6241936eeb9cdf15c72cde49e9.png" : factorProvider === CREDENTIFactorProvider ? "../../credenti-favicon.png" : '../../question_38x38.8453db9ea592a3015add7fb268491a3b.png');
                        if (!!authorizedFactors) {
                            for (const object of authorizedFactors) {
                                if (object.factorType + '-' + object.factorProvider === value) {
                                    setSelectedFactor(object);
                                    break;
                                }
                            }
                        }
                    }}
                >
                    {authorizedFactors?.map((item) => {
                        return <Select.Option key={item['factorType']}
                            value={item['factorType'] + '-' + item['factorProvider']}>
                            <img alt='img' className={"factor-authorize-list-logo"} src={getFactorImage(item['factorType'], item['factorProvider'])}></img>&nbsp;{item['factorType'] === HOTPFactorType ? "Feitian Hardware Token" : item['factorProvider'] === "DUO" ? "DUO Web" : item['factorType'] === oktaPushFactorType && item['factorProvider'] === oktaPushFactorProvider ? 'OKTA Verify With Push' : item['factorType'] === questionFactorType ? 'Security Question' : item['factorType'] === oktaTOTPFactorType && item['factorProvider'] === oktaTOTPFactorProvider ? 'OKTA Verify' : item['factorType'] === smsFactorType ? 'SMS Authentication' : item['factorType'] === googleTOTPFactorType ? "Google Authenticator" : item['factorType'] === emailFactorType ? "Email Authentication" : item["factorType"] === callFactorType || item["factorType"] === voiceFactorType ? "Voice Call Authentication" : item['factorProvider'] === yubicoTOTPHardwareProvider && item["factorType"] === yubicoTOTPHardwareType ? "Yubico Authentication" : item["factorType"] === RSATokenFactorProvider ? "RSA Authentication" : item["factorType"] == FACE_AUTHFactoryType && item["factorProvider"] == CREDENTIFactorProvider ? "Credenti Face Authentication" : ""}</Select.Option>
                    })}
                </Select>}</> : ""}
            </div>
            {!!errorDescription ? <div>
                <Alert type="warning" showIcon
                    description={errorDescription} ></Alert>
            </div> : ""}
            <div className={"factor-homepage-caption factor-homepage-div"}>
                <h4 className={"factor-header-help"}>{selectedOperation}</h4>
            </div>
            <div className={"factor-homepage-caption factor-homepage-div"}>
                <h3 className={""}>
                    {selectedFactor?.factorType === HOTPFactorType ? "Feitian Hardware Token" : selectedFactor?.factorProvider === 'DUO' ? "DUO Authentication" : selectedFactor?.factorType === questionFactorType ? 'Security Question' : selectedFactor?.factorType === smsFactorType ? 'SMS Authentication' : (selectedFactor?.factorType === oktaPushFactorType || selectedFactor?.factorType === oktaTOTPFactorType) && selectedFactor?.factorProvider === oktaTOTPFactorProvider ? 'OKTA Verify' : selectedFactor?.factorType === googleTOTPFactorType ? "Google Authenticator" : selectedFactor?.factorType === callFactorType || selectedFactor?.factorType === voiceFactorType ? "Voice Call Authentication" : selectedFactor?.factorType === emailFactorType ? "Email Authentication" : selectedFactor?.factorProvider === yubicoTOTPHardwareProvider && selectedFactor?.factorType === yubicoTOTPHardwareType ? "Yubico Authentication" : selectedFactor?.factorProvider === RSATokenFactorProvider ? "RSA Authentication" : selectedFactor?.factorProvider === CREDENTIFactorProvider ? "Crendenti Face Authentication" : ""}
                </h3>
            </div>
            <div className={"factor-homepage-caption factor-homepage-div"}>
                {selectedFactor?.factorProvider === 'DUO' ? "" : (selectedFactor?.factorType === HOTPFactorType || selectedFactor?.factorType === oktaTOTPFactorType || selectedFactor?.factorProvider === RSATokenFactorProvider) ? "Enter pass code" : !!selectedFactor?.hint ? selectedFactor?.hint : selectedFactor?.factorProvider === yubicoTOTPHardwareProvider && selectedFactor?.factorType === yubicoTOTPHardwareType ? "Click here, then tap your YubiKey" : selectedFactor?.factorProvider === CREDENTIFactorProvider ? "Scan the QrCode to begin the facial authentication" : ""}
            </div>
            {selectedFactor?.factorProvider !== "DUO" && !!selectedFactor?.factorType && selectedFactor?.factorType !== oktaPushFactorType && selectedFactor?.factorProvider !== CREDENTIFactorProvider ? <>
                <div className={"recovery-homepage-username recovery-homepage-div"}>
                    <div style={{ "display": "flex", gap: "10px" }}><Password
                        id="codeTextField"
                        onKeyUpCapture={(event) => {
                            if (event.key === 'Enter' && selectedFactor.factorProvider === 'YUBICO') {
                                setButtonLoading(1);
                                handleVerifyClick();
                            }
                        }}
                        style={{ flex: 3 }} placeholder={selectedFactor?.factorType === questionFactorType ? 'Enter answer' : selectedFactor?.factorProvider === yubicoTOTPHardwareProvider && selectedFactor?.factorType === yubicoTOTPHardwareType ? "" : "Enter code"} size="large"
                        onChange={(e) => {
                            setVerificationCode(e.target.value);
                        }}
                        value={verificationCode} />
                        {
                            selectedFactor?.factorType === smsFactorType ||
                                selectedFactor?.factorType === callFactorType || selectedFactor?.factorType === voiceFactorType ||
                                selectedFactor?.factorType === emailFactorType
                                ? <Button disabled={sendCodeButtonLabel === "Sent" ? true : false} size="large" type="default" style={{ flex: 1 }} onClick={() => {
                                    handleSendCodeClick(selectedFactor.factorType);
                                    setButtonLoading(0);
                                }}
                                    loading={buttonLoadings[0]}>{sendCodeButtonLabel}</Button> : ""}
                    </div>

                </div>
                {/* verify sms code button */}
                <div className="factory-homepage-button factory-homepage-div">
                    <Button className={""} type="primary" size="large" onClick={() => {
                        setButtonLoading(1);
                        handleVerifyClick();
                    }}
                        loading={buttonLoadings[1]}>Verify</Button>
                </div></>
                : ""}
            {!!challengeNumber ? <div className={"number-challenge-section"}><p>On your mobile device, open the Okta Verify prompt, then tap <b>{challengeNumber}</b> in Okta Verify to continue.</p><div className={"phone"}><div className={"phone-body"}>
                <div className={"phone--screen"}>
                    <span className={"phone--number"} data-se="challenge-number">{challengeNumber}</span></div>
                <div className={"phone--home-button"}></div></div></div></div> : ""}
            {!challengeNumber && selectedFactor?.factorProvider !== "DUO" && (selectedFactor?.factorType === oktaPushFactorType && selectedFactor?.factorProvider === oktaPushFactorProvider) ? <div className="factory-homepage-button factory-homepage-div">
                <Button disabled={sendPushButtonLabel === "Push sent!" ? true : false} className={""} type="primary" size="large" loading={buttonLoadings[2]} onClick={() => {
                    handleSendCodeClick(oktaPushFactorType);
                    setButtonLoading(2);
                }} >{sendPushButtonLabel}</Button>
            </div> : ''}
            {
                selectedFactor?.factorProvider == CREDENTIFactorProvider && selectedFactor?.factorType == FACE_AUTHFactoryType ?
                    <>
                        <div style={{ display: "flex", }}></div>
                        {
                            !!qrCodeValue &&
                            <>
                                <div style={{ position: "relative", marginTop: "10px" }}>
                                    <QRCode size={140} value={qrCodeValue} />
                                    {/* {qrCancel && <img src="../../X.png" style={{position:"absolute", left:"50%", transform:"translate(-50%,0)", backgroundColor:"#0000001f"}} width="140px"/>} */}
                                </div>
                                {/* <Button type="primary" size="large" disabled={false} loading={buttonLoadings[0]} onClick={handleVerifyTecproof}>Verify Authentication Status</Button> */}
                            </>
                        }
                        <Button type="primary" size="large" disabled={!retryTecproof} loading={buttonLoadings[0]} onClick={handleGenerateTecproof}>Retry{!retryTecproof && countDown > 0 && ` ${countDown}`}</Button>
                    </>
                    : ""
            }
            {/* DUO factor section */}
            {!!duoSectionVisi ? <div style={{ padding: "10px", backgroundColor: "#f7f7f7", border: "1px solid #d8d8d8", marginTop: "10px" }}>
                <Button loading={duoButtonLoadings[0]} onClick={() => {
                    setSelectedFactor(DUOFactorList.find(item => item.factorProvider === DUOPushFactorProvider && item.factorType === DUOPushFactorType));
                    setDuoPasscodeSectionVisi(false);
                    setDUOButtonLoadings([true, false, false, false]);
                    handleSendCodeClick(DUOPushFactorType, "push");
                }} style={{
                    marginRight: "10px",
                    marginBottom: "10px",
                    background: "#63B246",
                    borderColor: "yellow",
                }} size="large"
                    disabled={sendPushButtonLabel === "Push sent!" ? true : false}
                    type="primary">
                    {sendPushButtonLabel}
                </Button>
                <Button loading={duoButtonLoadings[1]} onClick={() => {
                    document.getElementById("duoCodeField")?.focus();
                    setSelectedFactor(DUOFactorList.find(item => item.factorProvider === DUOTOTPFactorProvider && item.factorType === DUOTOTPFactorType));
                    setDuoPasscodeSectionVisi(true);
                }} style={{
                    marginRight: "10px",
                    marginBottom: "10px",
                    background: "#63B246",
                    borderColor: "yellow",
                }} size="large"
                    type="primary"
                >
                    Enter a passcode
                </Button>
                {!!duoPasscodeSectionVisi ? <div style={{}}>
                    <Password size='large' style={{ marginBottom: "10px" }}
                        id="duoCodeField"
                        value={verificationCode}
                        onChange={(e) => {
                            setVerificationCode(e.target.value);
                        }} placeholder={"Enter Passcode"}>
                    </Password>
                    <div style={{ display: "flex" }}>
                        <Button onClick={() => {
                            setDUOButtonLoadings([false, false, true, false]);
                            handleVerifyClick();
                        }} loading={duoButtonLoadings[2]} size="large"
                            type="primary" style={{
                                width: "75%",
                                marginRight: "10px",
                                marginBottom: "10px",
                                background: "#63B246",
                                borderColor: "yellow",
                            }}>Submit</Button>
                        <Button onClick={() => {
                            setSelectedFactor(DUOFactorList.find(item => item.factorProvider === DUOFactorProvider && item.factorType === DUOFactorType));
                            setDUOButtonLoadings([false, false, false, true]);
                            handleSendCodeClick(DUOSMSFactorType, DUOSMSFactorType);
                        }} disabled={sendCodeButtonLabel === "Sent" ? true : false}
                            loading={duoButtonLoadings[3]}
                            size="large"
                            type="primary" style={{
                                width: "40%",
                                marginBottom: "10px",
                                background: "#63B246",
                                borderColor: "yellow",
                            }}>{sendCodeButtonLabel}</Button></div>
                </div> : ""}
            </div> : ""}

            <div style={{ paddingTop: "15px", paddingBottom: '10px' }}>
                <nav>
                    <Link to={recoveryHomePageRoute} onClick={() => {
                        clearSessionStorage();
                    }}>
                        <u>Back to recovery</u>
                    </Link>
                </nav>
                {!!brandingDetails?.instructions ? <div>
                    <div style={{
                        display: "flex",
                        color: "grey"
                    }}>
                        <div>Help/Instructions:</div>
                    </div>
                    <div style={{
                        display: "flex",
                        color: "grey"
                    }}>
                        <div><RichTextViewer content={content} /></div>
                    </div>
                </div> : ""}

            </div>
        </div>
    </div>
    </Skeleton>
}

